import React, { Component } from 'react';
import { Loading } from './LoadingComponent';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './BestDeals.css';
import { Card, CardImg, CardText, CardTitle } from 'reactstrap';
class ShopByMake extends Component {
  state = {
    width: 0
  };
  responsive = {
    0: {
      items: 3
    },
    420: {
      items: 3
    },
    768: {
      items: 6
    },
    1024: { items: 10 }
  };

  stagePadding = {
    paddingLeft: 30,
    paddingRight: 30
  };

  addSpace = key => {
    return key
      .split('-')
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(' ');
  };

  componentDidMount() {
    // if getVehicleMakeCount function exist in props then call it otherwise ignore
    this.props.getVehicleMakeCount && this.props.getVehicleMakeCount();
  }

  onSlideChange(e) {
  }

  onSlideChanged(e) {
  }
  slidePrevPage = () => {
    const currentIndex = this.state.currentIndex - this.state.itemsInSlide;
    this.setState({ currentIndex });
  };

  slideNextPage = () => {
    const {
      itemsInSlide,
      galleryItems: { length }
    } = this.state;
    let currentIndex = this.state.currentIndex + itemsInSlide;
    if (currentIndex > length) currentIndex = length;

    this.setState({ currentIndex });
  };

  render() {
    let vehicls = [];
    const {
      vehicleMakeCountSuccess,
      vehicleMakeCountLoader,
      vehicleMakeCountError
    } = this.props;

    if (vehicleMakeCountSuccess) {
      // eslint-disable-next-line array-callback-return
      vehicls = Object.keys(vehicleMakeCountSuccess).map((v, i) => {
        if (i < 24) {
          return (
              <div
                className="item"
                style={{ fontSize: '12px' }}
                key={i}
              >
                <Card className="popular-brands">
                  <CardImg
                    top
                    alt={'Card Emblem'}
                    src={require(`../assets/images/car-emblems/${
                      vehicleMakeCountSuccess[v][1]
                    }`)}
                  />
                  <CardTitle
                    style={{
                      fontWeight: 500,
                      fontSize: '10px'
                    }}
                  >
                    {this.addSpace(v)}
                  </CardTitle>
                  <CardText
                    style={{
                      fontWeight: 400,
                      fontSize: '8px'
                    }}
                  >
                    {' '}
                    ({vehicleMakeCountSuccess[v][0]})
                  </CardText>
                </Card>
              </div>
          );
        }
      });
      // eslint-disable-next-line array-callback-return
    }

    if (vehicleMakeCountLoader) {
      return (
        <div>
          <div className="headings-div text-center text-white mt-4 mt-lg-0" style={{ paddingTop: '15px', marginTop: '15px'}}>
            <h5 className="headings">Popular Brands</h5>
          </div>
          <Loading />
        </div>
      );
    } else if (vehicleMakeCountError) {
      return (
        <div>
          <div className="col-lg-6 col-sm-12 offset-lg-3">
            <div className="" style={{ textAlign: 'center' }}>
              <h2 className="headings">Popular Brands</h2>
            </div>
          </div>
        </div>
      );
    } else {
      const vehicles = vehicls.filter(element => {
        return element !== undefined;
      });

      return (
        <div style={{ backgroundColor: 'white', width: '100%' }}>
          <div className="col-lg-6 col-sm-12 offset-lg-3">
            <div className="headings-div-successful" style={{ textAlign: 'center', paddingTop: '15px' }}>
              <h2 className="headings">Popular Brands</h2>
            </div>
          </div>
          <div className="mt-3 " id="shopbymake">
            <AliceCarousel
              fadeOutAnimation={true}
              onSlideChanged={console.debug}
              infinite={false}
              buttonsEnabled={true}
              mouseDragEnabled={false}
              dotsDisabled={false}
              responsive={this.responsive}
              stagePadding={this.stagePadding}
              onSlideChange={this.onSlideChange}
            >
              {vehicles.slice(0, 10)}
            </AliceCarousel>
          </div>
        </div>
      );
    }
  }
}

export default ShopByMake;
