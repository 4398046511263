import ProductDetailsContainer from "./../components/ProductDetails";
import { connect } from "react-redux";
import {cartAction, vehicleAction} from "./../store/actions";

const mapStateToProps = state => {
  const {
    vehicleReducer: {
        getVehicleDetail,
        getVehicleDetailError,
        getVehicleDetailLoader,
        getPromotionalApiDataSuccess,
        getPromotionalApiDataError,
        getPromotionalApiDataLoader,
        getCountriesSuccess,
        getPriceSuccess,
        getStockRecordSuccess,
        addProductToBasketSuccess,
        addProductToBasketError,
        addProductToBasketLoader,
        getShippingMethodsSuccess,
        getShippingMethodsError,
        getShippingMethodsLoader
    },
    authReducer: {
      isLoggedIn
    }
  } = state;
  return {
    getVehicleDetail,
    getVehicleDetailError,
    getVehicleDetailLoader,
    getCountriesSuccess,
    getPromotionalApiDataSuccess,
    getPromotionalApiDataError,
    getPromotionalApiDataLoader,
    getPriceSuccess,
    getStockRecordSuccess,
    addProductToBasketSuccess,
    addProductToBasketError,
    addProductToBasketLoader,
    getShippingMethodsSuccess,
    getShippingMethodsError,
    getShippingMethodsLoader,
    isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVehicle: id =>
      dispatch(vehicleAction.getVehicle(id)),
    getCountries: payload =>
      dispatch(vehicleAction.getCountries(payload)),
    getPromotionalApiData: payload =>
      dispatch(vehicleAction.getPromotionalApiData(payload)),
    addProductToBasket: payload =>
      dispatch(vehicleAction.addProductToBasket(payload)),
    getShippingMethods: payload =>
      dispatch(vehicleAction.getShippingMethods(payload)),
    addCartProduct: payload =>
      dispatch(cartAction.addCartProduct(payload))
    // sideMenuAction: payload => dispatch(sideMenuAction.setSideMenu(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailsContainer);
