export const veh_data = 
[{"alfa-romeo": ["145", "146", "147", "155", "156", "159", "164", "166", "175", "1750", "179", "1900", "2000", "2600", "33", "33 Race", "33 Stradale", "33 Tt", "6C", "75", "8C Competizione", "90", "Alfa 6", "Alfasud", "Alfetta", "AR 51", "Arna", "Bella", "Berlina", "Brera", "Caimano", "Carabo", "Centauri", "Coupe", "Crosswagon", "Cuneo", "Dardo", "Disco Volante", "Eagle", "Giulia", "Giulietta", "GP 158", "GP 159", "Graduate", "GT", "GTA", "GTV", "GTV6", "Junior", "Kamal", "MiTo", "Navajo", "Nuvola", "Proteo", "RZ", "Scarabeo", "Scighera", "Spider", "Sportiva", "Sportut", "Sportwagon", "Sprint", "SZ"]},
{"aston-martin": ["15", "2-Litre", "AM Vantage", "Atom", "Cygnet", "DB2", "DB3", "DB4", "DB5", "DB6", "DB7", "DB9", "DBR2", "DBS", "Lagonda", "One-77", "Project Vantage", "Rapide", "V12", "V12 Vantage", "V8", "V8 Saloon", "V8 Vantage", "V8 Volante", "V8 Zagato", "Vanquish", "Vantage", "Virage"]},
{"audi": ["100", "200", "4000", "50", "5000", "80", "90", "A1", "A2", "A3", "A3 e-tron", "A4", "A5", "A6", "A7", "A8", "AD", "AL2", "Allroad", "Asso", "Avantissimo", "Avus", "Coupe", "Fox", "LeMans", "Nuvolari Quattro", "Pikes Peak Quattro", "Q3", "Q5", "Q7", "Quattro", "R8", "R8R", "Rosemeyer", "RS 5", "RS 7", "RS2", "RS3", "RS4", "RS5", "RS6", "S2", "S3", "S4", "S5", "S6", "S7", "S8", "Sport", "SQ5", "Steppenwolf", "Super 90", "TT", "TTS", "UR", "V8", "Variant"]},
{"bentley": ["Arnage", "Azure", "Brooklands", "Continental", "Continental Flying Spur", "Continental GT", "Continental GTC", "Continental Supersports", "Continental T", "Exp Speed 8", "Flying Spur", "Hunaudieres", "Java", "Mark VI", "MK V", "Mulsane", "Mulsanne", "R Type Continental", "S1", "S2", "State Limousine", "T", "T2", "Turbo"]},
{"bmw": ["3 series", "5 series"]},
{"bugatti": ["251", "Chiron", "EB 110", "EB 112", "EB 118", "EB 18-3 Chiron", "EB 18-4 Veyron", "EB 218", "ID 90", "Type 101", "Type 68", "Type 73", "Veyron"]},
{"cadillac": ["60", "61", "62", "6239D", "Allante", "ATS", "ATS Coupe", "Aurora", "Biarritz", "BLS", "Brougham", "Calais", "Catera", "Cimarron", "CTS", "CTS Coupe", "CTS Wagon", "CTS-V", "CTS-V Coupe", "CTS-V Wagon", "DeVille", "DTS", "Eldorado", "ELR", "Escalade", "Escalade ESV", "Fleetwood", "Imaj", "Le Mans", "LMP", "Park Avenue", "Seville", "Sixty", "Solitaire", "SRX", "STS", "STS-V", "V Sixteen", "Vizon", "XLR", "XTS "]},
{"chevrolet": ["2103", "Adventure", "Aerovette", "Alero", "APV", "Astro", "Astrovette", "Avalanche", "Aveo", "Bel Air", "Beretta", "Biscayne", "Blazer", "C-10", "Camaro", "Caprice", "Captiva", "Cavalier", "Celebrity", "Celta", "Chevelle", "Chevette", "Cheyenne", "Citation", "Citation II", "City Express", "Cobalt", "Colorado", "Comodoro", "Corsica", "Corvair", "Corvette", "Corvette Stingray", "Cruze", "DeLuxe", "DeVille", "El Camino", "Epica", "Equinox", "Evanda", "Express", "Express Cargo", "Grand Blazer", "Half-Ton", "HHR", "Impala", "Intimidator", "Journey", "K-20", "Kalos", "Kodiak", "Lacetti", "Lumina", "Malibu", "Matiz", "Metro", "Monte Carlo", "Monza", "Nomad", "Nova", "Nubira", "Optra", "Orlando", "Pickup", "Prizm", "Relsamo", "Rezzo", "S-10", "Sabia", "Silverado", "Silverado 1500", "Silverado 2500HD", "Silverado 3500HD", "Sonic", "Spark", "Spark EV", "Sprint", "SS", "SSR", "Suburban", "Tahoe", "Tandem 2000", "Tracker", "Trailblazer", "Trans Sport", "Traverse", "Trax", "Triax", "Uplander", "Vega", "Venture", "Vivant", "Volt", "XP 882", "XP 897 GT", "XP 898"]},
{"chrysler": ["160", "1609", "1610", "200", "200 CONVERTIBLE", "300", "Airflite", "Aspen", "Atlantic", "Avenger", "Aviat", "C", "CCV", "Centura", "Charger", "Chronos", "Cirrus", "Concorde", "Conquest", "Cordoba", "Crossfire", "Dart", "Daytona", "Delta", "Dodge 3700", "Dodge Phoenix", "E", "ES", "ESX 3", "Executive", "Expresso", "Grand Voyager", "GS", "Howler", "Imperial", "Java", "Laser", "Le Baron", "LHS", "Limousine", "Millenium", "Neon", "New Yorker", "Newport", "Pacifica", "Panel Cruiser", "Phaeton", "PT Cruiser", "PT Dream Cruiser", "Sebring", "Sedan", "TC", "Thunderbolt", "Town & Country", "Town and Country", "Valiant", "Viper", "Voyager", "Windsor", "Ypsilon"]},
{"citroen": ["1.6", "11", "15", "2CV", "7", "7A", "Activa", "Ak 350", "Ami", "Aventure", "AX", "Axel", "Berlingo", "Bijou", "BX", "C", "C 15", "C-Airdream", "C-Crosser", "C-Zero", "C1", "C2", "C3", "C4", "C5", "C6", "C8", "CX", "DS", "Dyane", "Dyane 4", "Dyane 6", "Eco 2000", "Eole", "GS", "GSA", "ID 19", "Karin", "LN", "LNA", "Mehari", "Mini-Zup", "Multispace", "Osee", "Picasso", "Pluriel", "Rally Raid", "Saxo", "SM", "Traction", "Visa", "Xanae", "Xantia", "XM", "Xsara", "ZX"]},
{"dacia": ["Duster", "Logan", "Sandero", "Supernova"]},
{"daewoo": ["Arcadia", "Brougham", "Chairman", "Espero", "Evanda", "Joyster", "Kalos", "Korando", "Lacetti", "Lanos", "Leganza", "Maepsy-Na", "Magnus", "Matiz", "Musiro", "Musson", "Nexia", "No 1", "Nubira", "Prince", "Rexton", "Rezzo", "Tacuma", "Tocsa", "Vada"]},
{"daihatsu": ["Altis", "Applause", "Atrai", "Bee", "Charade", "Charmant", "Compagno", "Consorte", "Copen", "Cuore", "Delta", "Domino", "Fellow", "Feroza", "Fourtrak TDX", "Freeclimber", "Gran Move", "Leeza", "Materia", "Micros 3l", "Mira", "Move", "Muse", "Naked", "Opti", "Rocky", "Sirion", "SP-4", "Taft", "Terios", "Terios II", "Trevis", "U4 B", "YRV"]},
{"dodge": ["600", "Aspen", "Avenger", "Caliber", "Caravan", "Challenger", "Charger", "Charger RT Concept", "Colt", "Conquest", "Copperhead", "Coronet", "Custom", "Dakota", "Dart", "Daytona", "Durango", "Dynasty", "Grand Caravan", "Hemi Super 8", "Intrepid", "Journey", "Kahuna", "Lancer", "M80", "Magnum", "Maxx Cab", "Mirada", "Monaco", "Neon", "Nitro", "Omni", "Polara", "Power Box", "Prowler", "Ram", "Razor", "Shadow", "Sidewinder", "Spirit", "Sprinter", "SRT Viper", "Stealth", "Stratus", "T-Rex 6x6", "Venom", "Viper", "WC 51"]},
{"ferrari": ["125", "125 F1", "125S", "126", "156", "158", "159S", "166", "195", "196", "206", "208", "212", "225", "246", "250", "250 GT", "250 GTE", "250 GTO", "250 LM", "250 Mille Miglia", "250 Testarossa", "255 S", "256 F1", "275", "288 GTO", "306", "308", "312", "328 GTB", "328 GTS", "330", "330GT", "340 America", "340 F1", "340 Mexico", "340 MM", "340 Spider", "342 America", "348", "348 TS Targa", "355 Spider", "355 TS", "360", "365 BB", "365 California", "365 GT", "365 GT4", "365 GTB", "365 GTC", "365 GTC 4", "365 GTS", "375 America", "375 Indy", "375 Mille Miglia", "3Z", "400", "400 Superamerica", "410 Superamerica", "412 GT", "412 MI", "412 T2", "456", "456M", "458", "458 Italia", "500 F2", "500 Mondial", "500 Superfast", "500 Testarossa", "512 BB", "512 BBi", "512 BBi Turbo", "512 F1", "512 M", "512 S", "512 TR", "550", "553 F1", "553 F2", "575 Superamerica", "575M Maranello", "599", "599 GTB Fiorano", "612", "612 Scaglietti", "625 F1", "735", "750", "801 F1", "850", "C62", "California", "D 50", "Dino", "Enzo", "F 2005", "F1 156", "F1 2000", "F1 86", "F1 88", "F1 89", "F1 90", "F1 93", "F310", "F333", "F355", "F399", "F40", "F430", "F50", "F512 M", "F55", "F550", "FF", "FF HELE", "GTO", "KS 360 Modena", "Maranello", "Mondial", "Mythos", "P2", "P5", "Pinin", "Rossa", "Superamerica", "Testarossa"]},
{"fiat": ["1100", "1200", "124", "125", "126", "127", "128", "130", "1300", "131", "132", "133", "1400", "1500", "1600", "1800", "1900", "2100", "2300", "500", "500e", "500L", "600", "750", "850", "8V", "Abarth", "Albea", "Argenta", "Armadillo", "Barchetta", "Berline", "Brava", "Bravo", "Cabriolet", "Campagnola", "Cinquecento", "Coupe", "Croma", "Dino", "Doblo", "Ducato", "Duna", "Ecobasic", "Ecobasis", "ESV", "Fiorino", "Freemont", "Grand Break", "Grande Punto", "Idea", "Legram", "Linea", "Marea", "Marea Weekend", "Mirafiori", "Multipla", "OT", "Palio", "Palio II", "Panda", "Punto", "Regata", "Ritmo", "Scudo", "Sedici", "Seicento", "Siena", "Stilo", "Strada", "Tempra", "Tipo", "Ulysse", "Uno", "V8", "Vivace", "X1-9"]},
{"ford": ["021 C", "12 M", "17", "17M", "2000", "24.7", "427", "49", "Aerostar", "Anglia", "Artic", "Aspire", "Bantam", "Bronco", "Bronco II", "C 100", "C-MAX", "C-Max Energi", "C-Max Hybrid", "Capri", "Coin", "Consul", "Contour", "Corsair", "Cortina", "Cougar", "Courier", "Crestline", "Crown Victoria", "Custom", "Desert Excursion", "E-150", "E-250", "E-350", "e-Ka", "E-Series", "E-Series Van", "E-Series Wagon", "Econoline", "Econovan", "Ecosport", "Edge", "Equator", "Escape", "Escort", "EX", "Excursion", "Expedition", "Explorer", "Extreme EX", "F", "F-150", "F-250", "F-250 Super Duty", "F-350", "F-350 Super Duty", "F-450", "F-450 Super Duty", "F-650", "Fairlane", "Falcon", "Festiva", "Fiesta", "Five Hundred", "Flex", "Focus", "Focus ST", "FPV BF GT", "Freestar", "Freestyle", "Fusion", "Fusion Energi", "Fusion Hybrid", "Galaxie", "Galaxy", "Gran Torino", "Granada", "GT", "GT 40", "GT 500", "GT 70", "Husky", "Ikon", "Indigo", "Ka", "Kuga", "Laser", "Libre", "Limited", "Lotus Cortina", "LTD", "Lynx", "Maverick", "Megastar II", "Meteor", "Model U", "Monarch", "Mondeo", "Mustang", "O21 C", "Orion", "Pilot", "Popular", "Prefect", "Probe", "Puma", "Ranchero", "Ranger", "Royale", "RS 200", "S-Max", "Saetta", "Scorpio", "Shelby GR-1 Concept", "Shelby GT 500", "Shelby GT500", "Sierra", "Skyliner", "ST 460", "Station Wagon", "Synergy 2010", "Taunus", "Taurus", "Taurus X", "TE-50", "Telstar", "Tempo", "Territory", "Think", "Think Neighbor", "Thunderbird", "TL-50", "Tonka", "Torino", "Tracer", "Transit Connect", "Transit Van", "Transit Wagon", "Triton", "TS-50", "Urban Explorer", "Vedette", "Versailles", "Windstar", "XR 8 Xplod", "Zephyr", "Zodiac"]},
{"honda": ["1300", "145", "Accord", "Accord Crosstour", "Accord Hybrid", "Accord Plug-In Hybrid", "Acty", "Argento Viva", "Avancier", "Ballade", "Capa", "City", "Civic", "Civic Del Sol", "Concerto", "CR-V", "CR-Z", "Crosstour", "CRX", "Element", "EV", "F1", "FCX", "Fit", "FR-V", "Fuya Jo", "HP-X", "HR-V", "Insight", "Inspire", "Integra", "J-VX", "Jazz", "Lagreat", "Legend", "Life", "Logo", "Mobilio", "Model X", "N III", "NSX", "Odyssey", "Passport", "Pilot", "Prelude", "Quintet", "Ridgeline", "S-MX", "S2000", "S500", "S600", "S800", "Saber", "Shuttle", "SSM", "Step Wagon", "Stream", "That", "Today", "Torneo", "Vamos", "Z"]},
{"hummer": ["H1", "H2", "H3"]},
{"hyundai": ["Accent", "Atos", "Azera", "Clix", "Coupe", "Dynasty", "Elantra", "Elantra GT", "Entourage", "Equus", "Euro 1", "Excel", "Galloper", "Genesis", "Genesis Coupe", "Getz", "Grandeur", "H1", "H100", "HCD-7", "HCD-III", "HED-5", "HED-6", "i10", "i20", "i30", "i40", "ix20", "ix35", "Lantra", "Marcia", "Matrix", "Neos", "Panel Van", "Pony", "Portico", "Santa Fe", "Santa Fe Sport", "Satellite", "Scoupe", "Sonata", "Sonata Hybrid", "Stellar", "Terracan", "Tiburon", "Tipper", "Trajet", "Tucson", "Veloster", "Veracruz", "XG", "XG 300", "XG 350"]},
{"infiniti": ["EX", "FX", "G", "G20", "G25", "G35", "G37", "I", "I30", "I35", "IPL", "J30", "JX", "M", "Q40", "Q45", "Q50", "Q60 Convertible", "Q60 Coupe", "Q70", "QX", "QX4", "QX50", "QX56", "QX60", "QX70", "QX80"]},
{"isuzu": ["117", "3.1", "4200", "Amigo", "Ascender", "Axiom", "Bellel", "Bellett", "D-MAX", "Florian", "Frontera", "Gemini", "Hombre", "I-280", "I-290", "I-350", "Impulse", "Kai", "KB", "Minx", "New Bellel", "Piazza", "Rodeo", "Stylus", "Trooper", "VehiCross", "VX-02", "VX4", "Wizard", "ZXS"]},
{"jaguar": ["220", "240", "3.8", "420G", "C-Type", "Concept Eight", "E Type", "E-Type", "F-Type", "Kensington", "Mark IV", "MK 10", "MK II", "MK IV", "MK IX", "MK V", "MK VII", "MK VIII", "MK X", "R Coupe", "R-D6", "S-Type", "Sovereign", "SS", "Type E", "Type S", "Type-C", "Vanden Plas", "X-300", "X-Type", "X400", "XF", "XJ", "XJ-13", "XJ220", "XJ6", "XJ8", "XJR", "XJR-11", "XJR-15", "XJS", "XK", "XK8", "XKA", "XKR", "XS"]},
{"jeep": ["Cherokee", "CJ", "CJ2", "CJ2A", "CJ3A", "CJ5", "CJ7", "Commander", "Compass", "Dakar", "Grand Cherokee", "Grand Cherokee SRT", "Grand Wagoneer", "Icon", "Jeepster", "Liberty", "MB", "Patriot", "Renegade", "Station Wagon", "Varsity", "Willys", "Willys 2", "Wrangler"]},
{"kia": ["Amanti", "Avella", "Borrego", "Brisa", "Cadenza", "Capital", "Carens", "Carnival", "Cee'd", "Cerato", "Clarus", "Elan", "Forte", "Joice", "K2700", "K900", "KCV-4", "Magentis", "Opirus", "Optima", "Optima Hybrid", "Picanto", "Potentia", "Pregio", "Pride Wagon", "Pro-ceed", "Retona", "Rio", "Rondo", "Sedona", "Sephia", "Sephia II", "Shuma", "Shuma II", "Sorento", "Soul", "Soul EV", "Spectra", "Spectra5", "Sportage", "Towner", "Venga", "Visto", "Xedos"]},
{"lamborghini": ["350 GT", "400", "Aventador", "Bravo", "Cala", "Concept S", "Countach", "Diablo", "Espada", "Flying Star", "Gallardo", "Huracan", "Islero", "Jalpa", "Jarama", "LM", "LM002", "Marco Polo", "Marzal", "Miura", "Murcielago", "P140", "Portofino", "Project P140", "Urraco"]},
{"lancia": ["037 Rallye", "2000", "A 112", "Appia", "Aprilia", "Ardea 3", "Aurelia", "Beta", "D 50", "Dedra", "Delta", "Dialogos", "ECV", "Flaminia", "Flavia", "Fulvia", "Gamma", "Hit", "Hyena", "Ionos", "K", "Kappa", "Lybra", "Megagamma", "Musa", "Nea", "Phedra", "Prisma", "Scorpion", "Stratos", "Thema", "Thesis", "Trevi", "Voyager", "Y10", "Ypsilon", "Zeta"]},
{"land-rover": ["109", "88", "A 109", "ALL-NEW Range Rover", "Defender", "Discovery", "Discovery 3", "Freelander", "I", "LR2", "LR3", "LR4", "Range Rover", "Range Rover Evoque", "Range Rover Sport", "Serie I", "Serie II", "Serie III"]},
{"lexus": ["CT", "CT 200h", "Daytona", "ES", "ES 300h", "ES 350", "FLV", "GS", "GS 350", "GS 450h", "GX", "GX 460", "HS", "IS", "IS 250", "IS 250 C", "IS 350", "IS 350 C", "IS F", "LF-C", "LFA", "LS", "LS 460", "LS 600h L", "LX", "LX 570", "Minority Report", "NX 200t", "NX 300h", "RC 350", "RC F", "RX", "RX 350", "RX 450h", "SC"]},
{"lotus": ["11", "16", "25", "33", "340 R", "49", "72", "79", "Carlton", "Eclat", "Elan", "Elise", "Elite", "Emme", "Esprit", "Etna", "Europa", "Evora", "Excel", "Exige", "Extreme", "GT 1", "M250", "Seven", "XI Le Mans"]},
{"maserati": ["124", "150S", "151", "228", "250", "300", "3200 GT", "3500", "350S", "4", "420", "420M", "430", "450", "4CL", "4CLT", "5000 GT", "6C", "8", "8CL", "8CLT", "A6", "A6G", "A6GCM", "A6GCS", "Auge", "Barchetta Straale", "Bi Turbo", "Boomerang", "Bora", "Coupe", "Ghibli", "Gran Turismo", "Gran Turismo 3500", "GranSport", "GranTurismo", "GranTurismo Convertible", "GT", "GT 3500", "Indy", "Khamsin", "Kubang", "Kyalami", "MC12", "Merak", "Mexico", "Mistral", "Quattroporte", "Royale", "Shamal", "Simun", "Spider 3500", "Spyder", "Tipo 60", "Tipo 61", "Tipo 64", "Tipo 65", "V8 GranSport"]},
{"maybach": ["57", "62", "Landaulet", "SW"]},
{"mazda": ["1000", "110 S", "121", "2", "3", "323", "5", "6", "616", "626", "787", "818", "929", "Activehicle", "Atenza", "AZ", "AZ-1", "B2300", "B2500", "B3000", "B4000", "Brown Collection Verisa", "BT-50", "Carol", "Chante", "Cosmo", "CU-X", "CX-05", "CX-09", "CX-5", "CX-7", "CX-9", "Demio", "Drifter", "DrifterX", "Etude", "Eunos", "Familia", "Kusabi", "Lantis", "Laputa", "Levante", "Luce", "Marathon", "MAZDA2", "MAZDA3", "Mazda5", "MAZDA6", "MazdaSpeed3", "MazdaSpeed6", "Midge", "Millenia", "Montrose", "MPV", "MS-6", "MS-8", "MS-9", "MX", "MX-3", "MX-5 Miata", "MX-6", "MX-Flexa", "Persona", "Premacy", "Protege", "R 360", "R-100", "Roadster", "Rustler", "RX 4 Coupe", "RX Evolv", "RX-01", "RX-2", "RX-3", "RX-4", "RX-7", "RX-8", "RX-Evolv", "Sentia", "SPEED3", "Spiano", "Sport Wagon", "SW-X", "Tribute", "Washu", "Xedos 9"]},
{"mclaren": ["650S Coupe", "650S Spider", "F1", "F1 GTR", "F1 LM", "M10", "M14", "M19", "M1C", "M21", "M23", "M8E", "MP4", "MP4-12C", "P1"]},
{"mercedes-benz": ["170 V", "170S", "180", "190", "200", "219", "220", "230", "230.4", "240", "250", "260", "280", "300", "300B", "300D", "300S", "320", "350", "380", "400", "420", "450", "500", "560", "600", "770", "A", "B", "Bionic Car Concept", "C", "C 111", "C 112", "C-Class", "C30", "CK", "CL", "CL-Class", "CLA-Class", "CLC", "CLK", "CLK GTR", "CLK LM", "CLS", "CLS-Class", "CW", "E", "E-Class", "E420", "E500", "F200", "F300 Life Jet", "G", "G-Class", "GL", "GL-Class", "GLA-Class", "GLK", "GLK-Class", "M", "M-Class", "MCC", "ML", "Ponton", "R", "S", "S-Class", "SC", "SE", "SL", "SL-Class", "SLK", "SLK-Class", "SLR McLaren", "SLS", "SLS AMG GT", "SLS AMG GT Final Edition", "Smart", "Sprinter", "Studie", "Swatch", "T", "T V-12", "V", "Vaneo", "Version Longue", "Viano", "Vision", "Vito", "W 110", "W 111", "W 123 Coupe", "W 136", "W 196"]},
{"mg": ["1100", "EX-E", "Magnett", "MGA", "MGB", "MGC", "Midget", "Rover", "RV8", "TD", "TF", "X10", "X20", "X80", "XPower", "YB", "ZR", "ZS", "ZT", "ZT-T"]},
{"mini": ["Clubman", "Cooper", "Cooper Clubman", "Cooper Countryman", "Cooper Coupe", "Cooper Paceman", "Cooper Roadster", "Countryman", "Coupe", "MK I", "MK II", "MK III", "MK IV", "MK V", "MK VI", "MK VII", "One", "Roadster"]},
{"mitsubishi": ["3000GT", "500", "A10", "ASX", "Carisma", "Celeste", "Challenger", "Colt", "Debonair", "Delica Space Gear", "Diamante", "Dingo", "Dion", "Eclipse", "eK", "Endeavor", "Field Guard", "FTO", "Galant", "Gaus", "Grandis", "GTO", "HSR-V", "i-MiEV", "Jeep", "L 200", "Lancer", "Lancer Evolution", "Lancer Sportback", "Magna", "Minica", "Mirage", "Montero", "Mum 500", "Nessie", "Outlander", "Outlander Sport", "Pajero", "Proudia", "Raider", "RPM 7000", "RVR", "Sapporo", "Shogun", "Shogun Pinin", "Sigma", "Space Liner", "Space Runner", "Space Star", "Space Wagon", "SSS", "SST", "SSU", "Starion", "SUP", "SUW", "Valley", "Verada"]},
{"nissan": ["100 NX", "110", "1400", "200 SX Silvia", "211", "240 C", "260 ZX", "270 R", "300 ZX", "350Z", "370Z", "400 R", "AA-X", "Almera", "Alpha", "Altima", "Armada", "Avenir", "AXY", "AZ-1", "Bluebird", "C 52", "Cedric", "Cefiro", "Chappo", "Cherry", "Cima", "Commercial", "CQ-X", "Crew", "Cube", "DS-2", "E20", "El Grand", "Fairlady", "Frontier", "Fusion", "Gloria", "Grand Livina", "GT-R", "Hardbody", "HyperMini", "Ideo", "Interstar", "Juke", "Lafesta", "Laurel", "Leaf", "Leopard", "Livina", "M", "Maxima", "Micra", "Mid4", "MM", "Moco", "Murano", "Murano CrossCabriolet", "Navara", "Note", "NP300", "NV", "NV200", "Pathfinder", "Patrol", "Pickup", "Pintara", "Pixo", "Platina", "Prairie", "Presea", "President", "Primera", "Pulsar", "Qashqai", "Quest", "R 390 GT1", "R 391", "Rasheen", "Rogue", "Santana", "Sedan", "Sentra", "Serena", "Silvia", "Skyline", "Sport", "Sports", "Stagea", "Stanza", "Sunny", "SUT", "Terrano", "Terrano II", "Tiida", "Titan", "Trailrunner", "Vanette", "Versa", "Versa Note", "Violet", "X-Trail", "Xterra", "XVL", "Z", "Zaroot"]},
{"opel": ["Admiral", "Agila", "Ampera", "Antara", "Ascona", "Astra", "Calibra", "Combo", "Commodore", "Concept M", "Corsa", "Diplomat", "Filo", "Frogster", "Frontera", "GT", "Insignia", "Kadett", "Kapitan", "Kraftfahrzeug", "Lotus Omega", "M", "Manta", "Meriva", "Monterey", "Monza", "Movano", "Olympia", "Omega", "P 1200", "Record", "Rekord", "Senator", "Signum", "Speedster", "Tigra", "Trixx", "Vectra", "Vita", "Vivaro", "Zafira"]},
{"peugeot": ["1007", "104", "106", "107", "202", "203", "204", "205", "206", "207", "3008", "304", "305", "306", "307", "308", "309", "4007", "4008", "403", "404", "405", "406", "407", "408", "5008", "504", "504D", "505", "508", "604", "605", "607", "806", "807", "907", "Boxer", "Escapade", "Expert", "H2O", "iOn", "Kart Up", "Nautilus", "Oxia", "Partner", "Peugette", "Promethee", "Proxima", "Quasar", "RC", "RCZ", "Sesame", "Touareg", "Tulip", "Type 202", "Vroomster"]},
{"porsche": ["1300", "3400", "356", "550 A", "718", "804", "904", "906", "907", "908", "910", "911", "912", "914", "917", "918 Spyder", "924", "928", "930", "935", "936", "944", "959", "962", "964", "965", "968", "993", "996", "Boxster", "Boxter", "Carrera GT", "Cayenne", "Cayman", "DP", "GT2", "GT3", "Karisma", "Kremer 935 K3", "Macan", "Mega Cabriolet Biturbo", "Pan Americana", "Panamera", "RGT", "Tapiro"]},
{"renault": ["10", "11", "12", "14", "15", "17", "18", "19", "20", "21", "25", "30", "4", "5", "6", "8", "9", "Alpine", "Argos", "Avantime", "Be Bop", "Caravelle", "Clio", "Colorale", "Dauphine", "Dauphine Gordini", "Duster", "Ellypse", "Espace", "Espider", "Etoile", "Express", "Fifties", "Floride", "Fluence", "Fregate", "Fuego", "Grand Espace", "Grand Scenic", "Helem", "Juva", "Kangoo", "Koleos", "Laguna", "Latitude", "Logan", "Ludo", "Megan", "Megane", "Modus", "P55", "R5", "Racoon", "RE", "Rodeo", "RS 11", "Safrane", "Sandero", "Scenic", "Scenic II", "Siete", "Spider", "Sport Spider", "Super 5", "Symbol", "Talisman", "Thalia", "Trafic", "Twingo", "Twizy", "TXE", "Vel Satis", "Wind", "Zo", "Zoe", "Zoom"]},
{"rolls-royce": ["100", "Camargue", "Corniche", "Ghost", "Ghost Series II", "Park Ward", "Phantom", "Phantom Drophead Coupe", "Silver Cloud", "Silver Cloud II", "Silver Dawn", "Silver Seraph", "Silver Shadow", "Silver Spirit", "Silver Spirit II", "Silver Spur", "Silver Wraith", "Wraith"]},
{"rover": ["100", "100 1.4 D", "200", "2000", "214", "216", "220", "25", "2600", "3.5", "3.5 Litre", "3500", "400", "414i", "416i", "420", "45", "600", "620i", "75", "800", "820", "825", "825i", "City", "Land Rover Discovery", "Metro", "MGF", "Mini", "Montego", "P2", "P4", "P5", "P5B", "P6", "Range Rover", "Streetwise", "Vitesse"]},
{"saab": ["3-Sep", "5-Sep", "750", "9-2x", "9-4X", "9-7X", "9-X", "90", "900", "9000", "900i", "92", "92B", "93", "94", "95", "96", "99", "MC", "Sonett", "Sonett II", "Sonett III"]},
{"seat": ["124", "127", "131", "133", "1400", "1430", "1500", "600", "Alhambra", "Altea", "Arosa", "Bolero", "Cordoba", "Exeo", "Formula", "Ibiza", "Inca Kombi", "Leon", "Malaga", "Marbella", "Ritmo", "Ronda", "Salsa", "Tango", "Toledo"]},
{"skoda": ["1000 MB", "110 L", "1200", "1202", "440", "Fabia", "Favorit", "Felicia", "L", "Montreux", "Octavia", "Polular 995", "Popular", "Praktik", "Rapid R", "Roomster", "S 110 R", "Superb", "Yeti"]},
{"smart": ["Brabus", "Cabrio Passion", "Coupe", "ForFour", "ForTwo", "Roadster", "Ultimate 112"]},
{"subaru": ["1.8", "1400", "360", "Alfa", "B", "B9 Tribeca", "Baja", "BRZ", "Coupe", "DL", "FF-1", "Forester", "G3X", "HM-01", "Impreza", "Impreza WRX", "Justy", "K 111", "Legacy", "Leone", "Mini Jumbo", "Outback", "Outback Sport", "Pleo", "R-2", "R2", "Rex", "STX", "SVX", "Traviq", "Tribeca", "Vivio", "WRX", "WX 01", "XT", "XV", "XV Crosstrek"]},
{"suzuki": ["Aerio", "Alto", "Baleno", "C2", "Cappuccino", "Cervo", "Covie", "Equator", "Escudo", "Esteem", "EV Sport", "F1", "Forenza", "Fronte", "Grand Vitara", "GSX R4", "Ignis", "Jimny", "Kizashi", "Lapin", "Liana", "Light", "LJ 20", "LJ 50", "Reno", "Samurai", "SC 100 GX", "Sea Forenza Wagon", "Sidekick", "SJ 410", "SJ 413", "Splash", "Swift", "SX", "SX4", "Twin", "Verona", "Vitara", "Wagon R+", "X2", "X90", "XL6", "XL7"]},
{"tesla": ["Roadster"]},
{"toyota": ["1000", "105", "2000GT", "4Runner", "AA", "Allion", "Altezza", "Aristo", "Ateva", "Auris", "Avalon", "Avalon Hybrid", "Avanza", "Avensis", "Aygo", "Bandeirante", "BB", "Blizzard", "Brevis", "Caldina", "Camry", "Camry Hybrid", "Carina", "Caserta", "Celica", "Celsior", "Century", "Chaser", "Coaster", "Condor", "Conquest", "Corolla", "Corona", "Corsa", "Cressida", "Cresta", "Crown", "Dyna", "Echo", "ES 3", "F-1", "FCHV 5", "FJ Cruiser", "Fortuner", "FXS", "GT1", "Hi-Ace", "Highlander", "Highlander Hybrid", "Hilux", "HMV", "Ipsum", "iQ", "Land Cruiser", "Lexcen", "Lite Ace", "Mark II", "Master RR", "Matrix", "Mega Cruiser", "Model F", "MR-S", "MR2", "MRJ", "Opa", "Paseo", "Picnic", "Pod", "Previa", "Prius", "Prius C", "Prius Plug-in", "Prius V", "Progress NC 300", "Publica", "Quantum", "Ractis", "RAV4", "RAV4 EV", "Retro Cruiser", "RSC", "RunX", "SA", "Sequoia", "Sera", "Sienna", "Soarer", "Solara", "Sparky", "Sport 800", "Sprinter", "Stallion", "Starlet", "Super", "Supra", "Tacoma", "Tazz", "Tercel", "Tundra", "Venture", "Venza", "Verossa", "Vitz", "Will", "Windom", "XYR", "Yaris"]},
{"vauxhall": ["Agila", "Astra", "Belmont", "Calibra", "Carlton", "Cavalier", "Chevette", "Corsa", "Cresta", "Firenza", "Frontera", "Magnum", "Maxx", "Meriva", "Monaro", "Nova", "Omega", "Royale", "Signum", "Sintra", "Tigra", "Vectra", "Velox", "Ventora", "Victor", "Viscount", "Viva", "VX Lightning", "VX220", "VX4", "Wyvern", "Zafira"]},
{"volkswagen": ["1 Litre", "1302", "1303", "1500", "1600", "181", "411", "AAC", "Beetle", "Beetle Convertible", "Bora", "Cabriolet", "Caddy", "Caravelle", "CC", "Citi", "Commercial", "Concept C", "Concept T", "Corrado", "Derby", "e-Golf", "Eos", "Eurovan", "Fox", "Fusca", "GLI", "Gol", "Golf", "Golf GTI", "Golf R", "Golf SportWagen", "GTD", "GTI", "Hac", "Iltis", "Jetta", "Jetta GLI", "Jetta Hybrid", "Jetta SportWagen", "K 70", "Karmann-Ghia", "Kombi", "LT 35 HR Panel Van", "Lupo", "Magellan", "Microbus", "Multivan", "New Beetle", "Parati", "Passat", "Phaeton", "Pickup", "Polo", "Quantum", "Rabbit", "Routan", "Santana", "Schwimmwagen", "Scirocco", "Sedan", "Sharan", "SP", "SP2", "T5", "Tiguan", "Touareg", "Touran", "Transporter", "Type 3 Squareback", "up", "Vento", "W12"]},
{"volvo": ["120", "122", "140", "144", "145", "164", "1800", "220", "240", "242", "244", "245", "260", "264", "265", "343", "360", "440", "460", "480", "66", "740", "760", "780", "850", "940", "960", "C30", "C70", "Duett", "Hatric", "P 1800", "P 1900", "P 210 Duett", "PV", "PV 544 1.8", "PV 60", "PV 801-10", "S40", "S60", "S70", "S80", "S90", "SCC", "V40", "V50", "V60", "V60 Cross Country", "V70", "V90", "X670", "XC60", "XC70", "XC90", "YCC"]},
{"morris": ["1100", "1800", "Cowley", "Isis", "Marina", "Mini Moke", "Minor", "Oxford", "Six", "Ten Four", "Traveller"]},
{"plymouth": ["Acclaim", "Barracuda", "Belvedere", "Breeze", "Cambridge", "Caravelle", "Colt", "Fury", "Gran Fury", "GTX", "Horizon", "Laser", "Neon", "Pronto Cruiser", "Pronto Spyder", "Prowler", "Reliant", "Road Runner", "Sundance", "Superbird", "Trail Duster", "Turismo", "Valiant", "VIP", "Volare", "Voyager"]},
{"morgan": ["4", "4 Plus 2100", "4 Seater", "44", "Aero", "Aero 8", "Aeromax", "F Super", "Plus 4", "Plus 4 Plus", "Plus 8", "Roadster"]},
{"ssangyong": ["Actyon", "Chairman H", "Chairman W", "Korando", "Kyron", "Musso", "Rexton", "Rodius", "Stavic", "W Coupe"]},
{"daf": ["44", "46", "600", "66", "750", "Daffodil"]},
{"bristol": ["400", "401", "402", "403", "404", "405", "406", "407", "408", "409", "410", "411", "412", "450", "603", "Beaufighter", "Blenheim", "Brigand", "Britannia", "Fighter", "Project Fighter"]},
{"gmc": ["Acadia", "Autonomy", "Canyon", "Envoy", "EV1", "Firebird", "Jimmy", "Safari", "Savana", "Sierra", "Sierra 1500", "Sierra 2500HD", "Sierra 3500HD", "Sonoma", "Suburban", "Terra 4", "Terracross", "Terradyne", "Terrain", "Typhoon", "Yukon", "Yukon XL"]},
{"daimler": ["4.2", "Conquest", "DE 27", "DE 36", "DK", "One-O-Four", "Super Eight"]},
{"proton": ["300", "400", "Arena", "Gen", "Gen-2", "Impian", "Juara", "Perdana", "Persona", "Saloon", "Satria", "Savvy", "Tiara"]}
];
