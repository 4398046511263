import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Alert, Col, Row,
} from 'reactstrap';
import 'react-input-range/lib/css/index.css';
import { Link } from 'react-router-dom';
import './ImgAndForm.css';
import {Loading} from "./LoadingComponent";

class ImgAndForm extends Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();

    this.handleSearch = this.handleSearch.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleRegChange = this.handleRegChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      value4: {
        min: 100,
        max: 5000
      },
      fromPrice: 0,
      toPrice: 1500000,
      regSelected: null,
      modelSelected: 'any',
      typeSelected: 'cars',
      message: '',
      carPrices: [
        0,
        100,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        22500,
        25000,
        27500,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        150000
      ]
    };
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  toggle(typeSelected) {
    if (this.state.typeSelected !== typeSelected) {
      this.setState({ typeSelected });
    }
  }

  error(message) {
    this.setState({ message });
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  handleRegChange(event) {
    this.setState({ regSelected: event.target.value });
  }

  handleModelChange(event) {
    this.setState({ modelSelected: event.target.value });
  }

  handleFromPriceChange = event => {
    this.setState({ fromPrice: event.target.value });
  };
  handleToPriceChange = event => {
    this.setState({ toPrice: event.target.value });
  };

  async handleSearch(event) {
    event.preventDefault();
    let { regSelected } = this.state;
    if (regSelected === null) {
      this.error('Registration number missing, please try again!');
      this.searchRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      return;
    }
    const query = `partner-one-search/${regSelected}`;
    this.props.onSearch(query);
  }

  keyToOption(key) {
    return key
      .split('-')
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(' ');
  }

  render() {
    let  {
      getProductCategoriesLoader,
      getProductCategoriesSuccess,
      getProductCategoriesError
    } = this.props
    let vehicles = []

    if (getProductCategoriesSuccess) {
      vehicles = getProductCategoriesSuccess.reduce(
        (acc, veh, i) => {
          let make = Object.keys(veh)[0];
          let vehModels = veh[make];
          return {
            makes: [
              ...acc.makes,
              <option key={make + i} value={make}>
                {this.keyToOption(make)}
              </option>
            ],
            models: {
              ...acc.models,
              [make]: [
                <option key={make + i + 0} value="any">
                  Any Model
                </option>,
                vehModels.map((model, i) => {
                  return (
                    <option key={make + model + i} value={model}>
                      {this.keyToOption(model)}
                    </option>
                  );
                })
              ]
            }
          };
        },
        { makes: [], models: [] }
      );
      
    }
    

    if (getProductCategoriesLoader) {
      return  (
        <div className="col-lg-12">
          <header className="headerbg d-flex">
            <div
              className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
              style={{ margin: '32px 0px'}}
              >
              <div className="container my-auto">
                <div className="bg-text" style={{height: '200px'}}>
                  <Loading />
                </div>
              </div>
            </div>
          </header>
        </div>
      )
    } else if (
      getProductCategoriesError
    ) {
      return  (
        <div className="col-lg-12">
          <header className="headerbg d-flex">
            <div
              className="col-sm-12 col-xs-12 offset-lg-0 col-lg-4 col-md-4"
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <div className="container my-auto">
                <div className="bg-text" style={{height: '200px'}}>
                  <div className="col-12">
                    <h4 style={{padding: '16px 0px'}}><strong>500.</strong> Thats an error</h4>
                    <p>The server encountered an error and could not complete your request</p>
                    <p>If the problem persist, please report your problem via chat </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
      )
    } else
    return (
      <div
        className="col-lg-12"
        style={{ padding: '0px' }}
      >
        <header className="headerbg d-flex">
          <div
            className="col-sm-12 col-xs-12 offset-lg-0 col-lg-5 col-md-6"
            style={{ paddingLeft: '0px', paddingRight: '0px' }}
          >
            <div className="container my-auto">
              <div className="bg-text">
                <h5 id="enter-reg" className="img-form-header"
                    style={{fontSize: '20px', fontWeight: 800, marginLeft: '16px', marginTop: '16px'}}>
                  Over 46 million checks completed in the last year</h5>
                <div
                  className="container"
                  style={{
                    backgroundColor: 'white',
                    padding: '5px 10px 20px 10px',
                    borderRadius: '0px'
                  }}
                >
                  <Form
                    onSubmit={this.handleSearch}
                    style={{ marginTop: '20px' }}
                  >
                    <div ref={this.searchRef}>
                    <FormGroup>
                      {this.state.message && (
                        <Alert color="danger">{this.state.message}</Alert>
                      )}
                    </FormGroup>
                    </div>
                    <FormGroup style={{ marginBottom: '2rem' }}>
                      <Row style={{borderRadius: "8px"}}>
                        <Col xs={1} sm={1}
                             style={{
                               padding: "4px",
                               textAlign: "center",
                               display: "flex",
                               backgroundColor: "#002d6f",
                               borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px",
                               flexDirection: "column",
                               justifyContent: "center"}}>
                          <Row style={{
                                  fontSize: "10px", color: "white",
                                  fontWeight: "bolder",
                                  display: "flex",
                                  justifyContent: "center", // Center items horizontally
                                  alignItems: "center", // Center items vertically
                                }}>
                            UK
                          </Row>
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "center", // Center items horizontally
                              alignItems: "center", // Center items vertically
                            }}
                          >
                            <img src={require("../assets/single-elements/icons8-great-britain-96.png")}
                                 alt="snapchat" height="32" width="32"/>
                          </Row>
                        </Col>
                        <Col xs={11} sm={11} style={{padding: "0px", width: "fit-content" }}>
                          <div style={{
                            display: "inline-block", backgroundColor: "#f7ce46", paddingRight: "8px",
                            paddingLeft: "8px",
                            borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}>
                            <Input
                              className="search-make uk-number-plate"
                              placeholder="ENTER REG"
                              onChange={e => this.handleRegChange(e)}
                              maxLength={8}
                              type="text"
                              name="q"
                              id="q"
                              autoComplete="off"
                              style={{border: '2px solid #424242',
                                padding: '1.5rem 0.55rem',
                                fontSize: '2rem',
                                fontWeight: 900,
                                textTransform: 'uppercase',
                                backgroundColor: '#f7ce46',
                                margin: '8px 0px',
                                width: "calc(8 * 1.6ch)", // Calculate width based on font size
                                textAlign: "center"
                              }}
                            >
                            </Input>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '1rem' }}>
                      <Input
                        type="submit"
                        name="search"
                        id="search"
                        className="btn btn-primary"
                        value="Search"
                      />
                    </FormGroup>
                    <Link to="/" className="advance-search">
                      Advanced Search <i className="fas">&#xf105;</i>
                    </Link>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="home-banner-img col-lg-7 col-md-6">
          </div>
        </header>
        <div className="col-lg-10" />
      </div>
    );
  }
}
// const genSlideStyle = (value) => {
//     return {
//         point: {
//             left: `calc(${value * 20}% - ${5 + 3 * value}px)`,
//         },
//         range: {
//             width: `${value * 20}%`,
//         },
//     };
// };

export default ImgAndForm;
