
import {withRouter} from "react-router-dom";
import React from "react";

const TermsAndConditions = (props) => {
   return(
     <div style={{maxHeight: '200px', overflow: 'auto', maxWidth: '500px', margin: 'auto', border: 'solid 1px gray', padding: '10px', borderRadius: '5px'}}>
        Ex UK Online Support programme terms and conditions.

        The purpose of the Ex UK Online Support programme is to reward Supporters for any new customers that they refer to Ex UK Online by properly advertising the exukonline.com domain through legitimate methods.

        By clicking on the ‘Start Earning Now’ button you agree to abide by the terms and conditions detailed in this Agreement.

        This Agreement contains the complete terms and conditions that apply to your participation as a member of the Ex UK Online Support programme. This Agreement constitutes the entire agreement between Ex UK Online and the Supporter and any pre-existing agreement or arrangement between Ex UK Online and the Supporter shall be terminated forthwith upon completion of this Agreement, save that any outstanding commissions owed to you prior to the revocation of your Supporter status will be credited to the Supporter’s account and can be used to purchase Ex UK Online products or services.

        Your Acceptance of these terms and conditions will be indicated by clicking on the ‘Start Earning Now’ button.

        Ex UK Online Support programme Terms and Conditions
        1. Definitions

        “this Agreement” shall mean the contents of the contract between Ex UK Online and the Supporter in respect of the Programme.

        “Ex UK Online” shall mean Ex UK Online Limited.
        ‘the Site’ shall mean exukonline.com website.

        ‘the Programme’ shall mean the Support programme as set out on the Site and that is governed by this Agreement.

        ‘the Link’ shall mean the HTML link provided from time to time by Ex UK Online to link the Supporter’s website to the Site.

        ‘the Supporter’ shall mean the party who agrees to take part in the Programme.

        2. The Programme

        2.1) The Programme shall be the Support programme as set out on the Site from time to time.

        2.2) Ex UK Online is entitled to vary, amend or cancel the Programme without giving notice to the Supporter. Ex UK Online may make any such change by publishing any revised terms on the Site.

        2.3) The Programme is expressly a business-to-business relationship and both Ex UK Online and the Supporter enter into it in a business capacity and not as a consumer.

        2.4) The Programme does not entitle the Supporter to represent themselves as an agent, partner or any other form of associate of Ex UK Online other than as an Supporter as expressly provided for in this Agreement.

        3. Suitability for the Ex UK Online Supporter Program
        3.1) Ex UK Online reserves the right to terminate this Agreement and the Supporter’s status at any time for any reason whatsoever at its sole discretion.

        3.2) In particular if Ex UK Online deems that the Supporter’s website is inappropriate for the Programme then this Agreement shall be terminated. The Supporter’s website may be deemed inappropriate if in the view of Ex UK Online it contains, promotes or contains links to sites that are:

        3.3) discriminatory, sexually explicit or violent material, or

        3.3) promote, depict or contain links to material that promote or depict discrimination based on race, gender, religion, national origin, physical or mental disability, sexual orientation, or age, or

        3.4) contains unlawful material, this shall include but not be limited to materials that may possibly violate another’s intellectual property rights, or

        3.5) contains information regarding, promotes or links to a site that provides information or promotes illegal activity, or

        3.6) for any other reason that is deemed by Ex UK Online (at its sole discretion) to be unsuitable.

        3.7) Upon Clicking on the ‘Start Earning Now button’ you are automatically accepted on the Programme. Ex UK Online reserves the right to withdraw Supporter status at anytime after you have been accepted onto the Programme for any reason that Ex UK Online deems relevant. Acceptance into the Programme does not mean that Ex UK Online has specifically approved the Supporter or its website.

        4. Legitimate methods of advertising
        4.1) In order to receive commission’s for referring customers to Ex UK Online, the Supporter must engage in proper advertising. Supporters found to be engaging in improper advertising shall have their agreement terminated and their Supporter status revoked. Ex UK Online shall be the sole and absolute arbiter of what constitutes proper advertising.

        4.2) A non-exhaustive list of examples of improper advertising shall include but not be limited to:

        4.2.1) the forwarding of any urls direct to exukonline.com (this includes misspells of the above trademark domain); and

        4.2.2) forcing cookies through iframes;

        4.2.3) advertising through third party networks; with the exception arising from Clause 4.3 below;

        4.2.4) brand bidding (including misspells) for PPC advertising,

        4.2.5) including your Ex UK Online Supporter link within Unsolicited Commercial Email (UCE) or SPAM,

        4.2.6) including any of Ex UK Online url in search adverts.

        4.3) Supporters are permitted to promote Ex UK Online through various social media communities, as a limited exception to Clause 4.2.3 above. However, Supporters are not permitted to create groups or specific web pages in social communities whereby they purport or hold themselves out to be representatives of Ex UK Online.

        4.4) Ex UK Online reserves the right to conclude that you have engaged in an improper method of advertising according to our standards, at our sole discretion. We may come to such a conclusion even if it is based upon our opinion or mere suspicion or belief and is without any duty on Ex UK Online to prove that our opinion or suspicion is well-founded; and even if our opinion is proven not to be well-founded or if other hosting/domain name companies have not deemed it to be an improper method of advertising.

        4.5) Any Supporter found to be adopting improper methods of advertising for the purpose of the Programme shall be deemed to have been in breach of this Agreement and shall have their Supporter status revoked. Such an Supporter will therefore immediately cease in being an Supporter of Ex UK Online.

        4.6) Any sales that have been generated through improper advertising will not qualify for commission or credit from Ex UK Online.

        4.7) Any Supporter found to be utilising these or any other improper methods of advertising may be required to pay back any such commission that has been paid by Ex UK Online to them. If Ex UK Online elects not to enforce this right it shall not be deemed to be a waiver of any other rights that it may have under this Agreement.

        5. The Procedure
        5.1) Subject to the terms of this Section, we will provide to you all Links, and any related banners, graphics, or text advertisements necessary to promote and offer the Ex UK Online Services to your site’s visitors and/or its members. The Ex UK Online link may contain any picture, text, logo, graphic, that maybe be attributed with Ex UK Online.

        5.2) Ex UK Online reserves the right to change the content and or display of the Link from time to time in our sole discretion. The link will connect your site with the area on our site (the landing page) where your referral may apply for Ex UK Online products directly with us. The presence of the Ex UK Online link on your site will serve to identify you as a member of our Support programme.

        6. The Payment of Commission
        6.1) Once a customer has arrived at our website having followed the Link, their actions on our website will be tracked for 120 days using a cookie. You will earn commission on their first order that is placed during the 120 day period. Ex UK Online will not be responsible for commissions missed due to the cookie being deleted or blocked or in any other way failing to track the customer from the Link.

        6.2) Supporters will only earn commission fees only with respect to activity on our site occurring directly through the Link that will appear on their website or if the customer redeems a voucher code assigned to a Supporter on a successful order.

        6.3) Ex UK Online is under no obligation whatsoever to pay any commission to any Supporter who does not strictly follow this Agreement as published from time to time.

        6.4) Ex UK Online reserves the right to take legal action against any Supporter that commits fraud, or conspiracy to defraud and to recover any commissions paid to an Supporter which was earned as a result of such fraud. For the purposes of this agreement fraud shall include but not be limited to wittingly violating the terms of this Agreement.

        6.5) Ex UK Online will only make a payment to the Supporter when the level of commission due at the end of a given calendar month is above the commission balance threshold.

        6.6) the commission balance threshold for the purpose of this agreement is USD25.

        6.7) Ex UK Online reserves the sole right to change the commission balance threshold at any time it sees fit without the prior the consent of its Supporters. In the event of a commission balance threshold change Ex UK Online shall notify all its Supporters of the change before hand. If any modification to the commission balance threshold or the Agreement as a whole is not acceptable to an Supporter, the Supporter shall be entitled to terminate this Agreement. If an Supporter terminates this Agreement for any reason whatsoever then it shall not be entitled to any commission payments earned after it has terminated this Agreement.

        6.7) The Supporter’s continuing participation in the Programme constitutes its acceptance of any change to the commission balance threshold or to any other part of this Agreement.

        6.8) Supporters that exceed the commission balance threshold will automatically be sent the monies to their Mobile wallet account or paypal account. Supporters that do not exceed the commission balance threshold can apply their balance as credit and use this credit to purchase new Ex UK Online products or services.

        6.9) Supporters that do not reach the USD25 commission balance threshold in one calendar month will not lose their accrued commission, instead this commission can be used as a credit. Their accrued credit for that particular calendar month will be carried over and applied to the next calendar month, this process shall continue until the client reaches the commission balance threshold when they will be entitled to receive a payment from Ex UK Online.

        6.10) Ex UK Online will only pay out commissions on sales that are 45 days old.
        Commissions for sales over 45 days old will be paid once the USD45 threshold is reached.

        6.11) Once payment are approved and processed. Supporters shall allow up to 5 working days to receive their funds. It is the sole responsibility of an Supporter to make sure that their contact details are up to date and accurate on the Ex UK Online system in order to facilitate the monies reaching them.

        6.15) The rates of commission payable to the Supporter will depend on the level of the Supporter

        7. Ex UK Online’s Obligations
        Ex UK Online agrees to undertake the following obligations:

        7.1) provide voucher codes and all information necessary to allow the Supporter to make necessary the Link from the exukonline.com to the Supporter’s site,

        7.2) processing all orders for Ex UK Online products or services placed by a referral following the Link,

        7.3) tracking the number and amount of relevant sales generated through the Link and Voucher code,

        7.4) providing information to you regarding commission payments,

        7.5) credit card authorisations, payment processing, cancellations, returns, and all other related customer service for Ex UK Online for the purpose of our business, and

        7.6) establishing the commission balance threshold, payment frequency and payouts of earned commissions as contained in Section 6 of this Agreement.

        8. The Supporter’s Obligations
        The Supporter agrees to be solely responsible for the following and shall keep Ex UK Online fully indemnified in respect of:

        8.1) the content of the Supporter’s site or any site that they may be connected to,

        8.2) any misrepresentation of Ex UK Online or its products or services,

        8.3) making of any false claims, representations or warranties in connection with Ex UK Online,

        8.3) ensuring that your site and your products and services that you offer from your site comply with all applicable copyright, trademark, any intellectual property right, Data Protection, anti-spam or any other applicable law,

        8.4) obtaining permission to use another party’s copyrighted or any other proprietary material,

        8.5) the development, operation and maintenance of the Supporter’s site and for all materials that appear on it. For the purposes of this Agreement this shall include but not be limited to, the technical operation of your site and all related equipment; the accuracy and propriety of materials posted on your site; and ensuring that materials posted on your Site do not violate or infringe upon the rights of any third party and are not libellous or otherwise unlawful or illegal. Ex UK Online hereby disclaim all liability for all such matters,

        8.6) Supporters also agree to indemnify and hold harmless Ex UK Online, its parent company, sister companies, subsidiaries and Supporters, and their directors, officers, employees, agents, shareholders, partners, members and other owners, against any and all claims, actions, demands, liabilities, losses, damages, judgments, settlements, expenses, and costs insofar as such arise out of or are based on, or in any way connected with this Agreement.

        8.7) complying with all applicable EU- and national laws and regulations in force from time to time this shall include, but not be limited to, the EU-directive 2002/58/EC, and

        8.8) the payment of all tax and national insurance payable on any payments made to you by Ex UK Online.

        9. Right to Name as a Reference Customer
        9.1) Supporters shall not create, publish, distribute, or permit any written or graphical material that makes reference to Ex UK Online other than those mentioned in this Agreement or otherwise provided by Ex UK Online, without the prior written consent of Ex UK Online.Ex UK Online reserves the right to refuse any request for consent under this Agreement.

        10. The License
        10.1) Ex UK Online grants to the Supporter a non-exclusive, non-transferable, revocable right to access the exukonline.com site through the Link solely in accordance with the terms of this Agreement. This license shall be used solely in connection with the Link, and it will extend to the use of our logos, trade names, trademarks and similar identifying material relating to Ex UK Online and which Ex UK Online shall provide to the Supporter, for the sole purpose of establishing the Link to exukonline.com so customers can purchase Ex UK Online products and services.

        10.2) Supporters cannot modify or change the Link or any other materials provided by Ex UK Online in any way.

        10.3) Other than establishing the Link, the Supporter shall not make any use of any of the licensed materials noted in the aforementioned paragraph provided by Ex UK Online without first obtaining the prior written consent of Ex UK Online. Supporters shall not use the Licensed Materials in any manner that is inappropriate or that is in any way detrimental to the Ex UK Online brand or any other brands.

        10.4) Ex UK Online reserves all of its rights in the materials provided and all of its other proprietary rights. Ex UK Online shall be entitled to revoke this license to use the Link or the materials at any time and at its sole discretion.

        10.5) The licenses described in this Section shall expire upon the termination of this Agreement.

        10.6) Any inappropriate use of the Link, text, banners or other advertisements not expressly approved of in writing or provided by Ex UK Online may be cause for immediate termination of this Agreement.

        11. Terms of the agreement
        11.1) The terms of this Agreement will begin upon your signup with the Programme and will end when your Supporter account is terminated.

        11.2) Upon the termination of this Agreement any commission that has not yet reached the commission threshold shall be turned into credit which can only be used to purchase products or services from Ex UK Online. Supporters that have reached the USD25 commission threshold will be paid a cheque upon the termination of the Agreement subject always to the terms of this Agreement as shall be published from time to time.

        12. Modification
        112.1) Ex UK Online reserves the right to modify or otherwise change the terms of this Agreement at any time as it sees fit. Ex UK Online shall make such modifications by way of publishing revised terms on the Site. Supporters only remedy in the event of revised terms of this Agreement being published shall be to terminate this Agreement. An Supporter shall be deemed to have accepted of any modification to this Agreement as published from time to time.

        13. Limitation of Liability
        13.1) Ex UK Online shall not be liable to the Supporter or to any other person, for indirect, incidental, or special damages, lost profits, loss of goodwill, lost savings, or any other form of consequential damages, regardless of the form of action, even if Ex UK Online has been advised of the possibility of such damages, whether resulting from breach of its obligations under this Agreement or otherwise.

        13.2) Ex UK Online’s entire liability in respect of any liability arising under this agreement will not exceed the total commission fees paid or payable to the Supporter under this Agreement.

        13.3) Ex UK Online makes no warranties, either express or implied, concerning the performance or functionality of the Ex UK Online services, or the Programme as a whole. This includes but is not limited to the Link or any other Supporter advertisements and hereby expressly disclaims all implied warranties, including warranties of merchantability or fitness for a particular use or purpose.

        13.4) Ex UK Online shall under no circumstances be liable to the Supporter or to any other person or entity for any loss, injury, or damage, of whatever kind, resulting from or arising out of any mistakes, errors, omissions, delays, or interruptions in the receipt, transmission, or storage of any messages or information arising out of or in connection with the Programme or Ex UK Online.

        14. Governing Law
        14.1) This Agreement is governed by, and is construed in accordance with the laws of England and Wales.

        14.2) The Courts of England and Wales shall have jurisdiction to hear any disputes arising from this Agreement.

        14.3) Ex UK Online shall not be liable for the legality of Ex UK Online service in countries other than the United Kingdom.

        14.4) Supporters are solely responsible for the legality of the use of the service if the Supporter in question is registered to Ex UK Online service from a country other than the United Kingdom or if the Supporter’s website is on a server in a country other than the United Kingdom.

        15. Acceptance
        15.1) By clicking on the ‘Start Earning Now’ button the Supporter acknowledges that they have read the terms and conditions of this Agreement, understand them and agree to be bound by them.
      </div>
    );

}

export default withRouter(TermsAndConditions);
