
const routeTo = (history, page) => {

  if (page === 'Downline') {
    history.push({
      pathname: "/my-downline",
    })
  }
  if (page === 'Advertising') {
    history.push({
      pathname: "/advertising-tools",
    })
  }
  if (page === 'Training') {
    history.push({
      pathname: "/supporter-training",
    })
  }
  if (page === 'Dashboard') {
    history.push({
      pathname: "/supporter-dashboard",
    })
  }
}


export default(routeTo)