import React from 'react';
import {
    Button, Input, InputGroup,
    InputGroupAddon, Row, Col
} from 'reactstrap';
import {Link} from "react-router-dom";
// import {facebookIcon} from "./../assets/images/single-elements/icons8-facebook-48.png";
// import Garantee from './Garantee'
function Footer() {
    return (
        <div>
        <div className="footer-spacer">
             {/*<Garantee/>*/}
        <div className="footer">
            <div className="middle-footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row  mt-4">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div style={{paddingBottom: "40px", display: "None"}}>
                                        <span className="footer-link-text">SOCIALS</span>
                                        <div style={{paddingTop: "16px"}}>
                                            <Row>
                                                <Col style={{paddingLeft: "0px"}}>
                                                    <a href="https://www.facebook.com/profile.php?id=100073710447594"
                                                       style={{ cursor: "pointer"}}>
                                                        <img src={require("../assets/single-elements/col-icons8-facebook-48.png")}
                                                             alt="facebook" height="32" width="32"/>
                                                    </a>
                                                </Col>
                                                <Col style={{paddingLeft: "0px"}}>
                                                    <a href="https://www.instagram.com/doorstepbooze/"
                                                       style={{ cursor: "pointer"}}>
                                                        <img src={require("../assets/single-elements/col-icons8-instagram-48.png")}
                                                             alt="instagram" height="32" width="32"/>
                                                    </a>
                                                </Col>
                                                <Col style={{paddingLeft: "0px"}}>
                                                    <a href="https://twitter.com/DoorstepBooze"
                                                       style={{ cursor: "pointer"}}>
                                                        <img src={require("../assets/single-elements/col-icons8-twitter-48.png")}
                                                             alt="twitter" height="32" width="32"/>
                                                    </a>
                                                </Col>
                                                <Col style={{paddingLeft: "0px"}}>
                                                    <a href="https://www.snapchat.com/add/doorstep_booze?share_id=MTY1QUEy&locale=en_GB"
                                                       style={{ cursor: "pointer"}}>
                                                        <img src={require("../assets/single-elements/col-icons8-snapchat-48.png")}
                                                             alt="snapchat" height="32" width="32"/>
                                                    </a>
                                                </Col>

                                            </Row>

                                        </div>
                                    </div>
                                    <div>
                                        <span className="footer-link-text">CONTACT INFORMATION</span>
                                        <ul className="list-unstyled info"
                                            style={{ paddingBottom: "32px"}}>
                                            <li className="number"
                                                style={{lineHeight: "16px"}}>
                                                <div className="footer-link-text-div">
                                                    <a href="tel:07756 287251"> 0330 055 0498 </a>
                                                </div>
                                                <div className="footer-link-text-div">Sun to Thur - 6pm to 4am</div>
                                                <div className="footer-link-text-div">Fri to Sat - 6pm to 6am</div>
                                            </li>
                                            <li className="support-mail"
                                                style={{lineHeight: "16px"}}>
                                                <div className="footer-link-text-div">
                                                    contact@reg-check-mate.co.uk
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <span className="footer-link-text">PRIVACY & TERMS</span>
                                    <div style={{padding: "16px 0px 32px 0px"}}>
                                        <Link  style={{paddingLeft: "0px", marginLeft: "0px", color: "#5484f4"}}
                                               to="/privacy-policy"
                                               className="mr-3">Privacy Policy  </Link>
                                        <Link style={{paddingLeft: "0px", marginLeft: "0px", color: "#5484f4"}}
                                              to="/about-us"
                                              className="mr-3">Terms & Conditions</Link>
                                    </div>
                                    <div>
                                        <span className="footer-link-text">HOW IT WORKS</span>
                                    </div>

                                    <div className="footer-text" style={{paddingTop: "16px", color: "#cccccc"}}>
                                        <p>
                                            Looking to check the history of a car before buying?
                                            <br/> Check out Reg Check Mate!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="third-footer-section" style={{display: "None"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h6>Join Our Newsletter Now</h6>
                            <p>Get E-mail updates about out latest shop and special offers.</p>
                        </div>
                        <div className="col-lg-4">

                            <InputGroup>
                                <Input type="text" className="footer-input" placeholder="Enter your mail" />
                                <InputGroupAddon addonType="prepend"><Button id="btn-addon">SUBSCRIBE</Button></InputGroupAddon>
                            </InputGroup>


                        </div>
                    </div>
                </div>
            </div>
            <div className="fourth-footer-section" style={{display: "None"}}>
            </div>
        </div>
    </div>
        </div>
    )
}

export default Footer;