import React, { Component } from 'react';
import {
  Alert, TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Input, Label, Button, Row, Col
} from 'reactstrap';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import classnames from 'classnames';
import Activate from './ActivateComponent';
import { withCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import credentials from "../config/credentials";


class Login extends Component {
    constructor(props) {
        super(props);
        this.registerRef = React.createRef();
        this.loginRef = React.createRef();
        this.state = {
            activeTab: "1",
            email: "",
            first_name: "",
            last_name: "",
            confirmEmail:"",
            password:"",
            confirmPassword: "",
            country:"",
            error: false,
            message:"",
            activeLogin: 0,
            countries : [],
            hideSwitch: false,
            multiView: 0
        };

       this.ResetState = this.ResetState.bind(this);
       this.handleSocialLogin = this.handleSocialLogin.bind(this);
       this.socialLogin = this.socialLogin.bind(this);
       this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this)
       this.toggle = this.toggle.bind(this);
       this.onChange = this.onChange.bind(this);
       this.hindle = this.renderOptions.bind(this);
       this.handleSignup = this.handleSignup.bind(this);
       this.handleSignin = this.handleSignin.bind(this);
       this.handlePasswordReset = this.handlePasswordReset.bind(this);
       this.handleFormFilling = this.handleFormFilling.bind(this);
       this.handleFormValidation = this.handleFormValidation.bind(this);
       this.resetError = this.resetError.bind(this);

  }


  ResetState(){
      this.setState({
        email: "",
        confirmEmail:"",
        password:"",
        country: "",
        error: false,
        message:""
    })
  }

  resetError(){
    this.setState({error: false, message: ""})
  }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                hideSwitch: false
            });
            this.ResetState();
        }
    }

    onChange(event){
        const {value, name} = event.target;

        this.setState( (prevState) =>  prevState[name] = value);

    };

  handleFormFilling(event, action) {
    event.preventDefault();
    if (action === "next"){
      let isValid = this.handleFormValidation(this.state.multiView)
      if (!isValid){
        return;
      }
      this.setState({multiView: (this.state.multiView + 1)})

    } else {
      this.setState({multiView: (this.state.multiView - 1)})
    }
  }

  handleFormValidation( stage ){

    const { email, password, confirmPassword, first_name, last_name} = this.state;
    if (stage === 0) {
      if(first_name === "" || last_name === "")
      {
        this.setState({error: true, message: "First & Last name required to register"});
        this.registerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        return false;
      } else {
        this.resetError()
        return true;
      }
    }
    if (stage === 1) {
      if(email === "" || password === "")
      {
        this.setState({error: true, message: "Email and Password required to register"});
        this.registerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        return false;
      } else {
        if(password !== confirmPassword)
        {
          this.setState({error: true, message: "Passwords do not match"})
          this.registerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
          return false;
        } else {
          this.resetError()
          return true;
        }
      }
    }
    if (stage === 3) {
    }
  }


    handleSignup(event) {
        event.preventDefault();

        const { email, password, country, confirmPassword, first_name, last_name} = this.state;

        this.props.registerUser && this.props.registerUser({
            first_name: first_name,
            last_name: last_name,
            email,
            confirm_email: email,
            password,
            country
        });
        
    }

    socialLogin (response) {
      if(response.accessToken){
        var payload = {access_token: response.accessToken}
        this.handleSocialLogin(payload)
      }
    }

    handleSocialLogin(payload) {
        this.props.socialApiAuth && this.props.socialApiAuth({payload});
    }

    handleSocialLoginFailure(response) {
      this.setState({error: true, message: "Authentication Failed"});
      const fetchLog = fetch(`${credentials.API_URL}/log-social-auth/`, {
        method: 'POST',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(response)
      })
      fetchLog.then(response => {
      return response;
      })
    }

    handleSignin(event) {
      event.preventDefault();
      const {email, password} = this.state;
      const {history, location} = this.props;

      if(email === "" || password === "")
      {
          this.setState({error: true,message: "Required info is missing"});
          return;
      }
      const { cookies } = this.props;

      this.props.apiTokenAuth && this.props.apiTokenAuth({
          cookies,
          location,
          history,
          credentials: {
              username: email,
              password: password
          }
      });
    }

    handlePasswordReset(event) {
      event.preventDefault();
      const { email } = this.state;

      if(email === "")
      {
        this.setState({error: true, message: "Email address is required to reset password"})
        return;
      }

      this.props.passwordReset && this.props.passwordReset({"email": email})

    }

    componentDidMount(){
      const { history, location, isLoggedIn, match } = this.props
      if (isLoggedIn) {
        history.push("/profile", location)
      }
      if (match.path === '/auth-reset') {
        this.setState({activeTab: "3"})
      }

        this.props.getCountries && this.props.getCountries();
    }

    componentDidUpdate(prevProps) {

        let {
          registerUserSuccess,
          registerUserError,
          apiTokenAuthError,
          passwordResetSuccess,
          apiTokenAuthSuccess,
          location,
          history,
          isLoggedIn,
          cartProducts, mergeCartSuccess } = this.props;

        if (apiTokenAuthSuccess && prevProps.apiTokenAuthSuccess !== apiTokenAuthSuccess) {
          history.push("/", location)
        }

      if (isLoggedIn && prevProps.isLoggedIn !== isLoggedIn) {
        if (cartProducts && cartProducts.length > 0) {
          this.props.mergeCart && this.props.mergeCart({
              itemsToAdd: JSON.stringify(cartProducts)
          });
          this.props.clearCart("Nada")
          history.push("/my-basket", location)
          return
        }
        history.push("/profile", location)
      }

      if (mergeCartSuccess && prevProps.mergeCartSuccess !== mergeCartSuccess) {
        this.props.clearCart("Nada")
        history.push("/my-basket", location)
      }

        if (registerUserSuccess && prevProps.registerUserSuccess !== registerUserSuccess) {
            this.setState({ activeLogin: registerUserSuccess.activeLogin })
        }

        if (registerUserError && prevProps.registerUserError !== registerUserError) {
            this.registerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            this.setState({ error: true, message: registerUserError.message })
        }
        
        if (apiTokenAuthError && prevProps.apiTokenAuthError !== apiTokenAuthError) {
          this.loginRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          if (apiTokenAuthError.message.status === 404) {
            this.setState({error: true, message: apiTokenAuthError.message.message})
          } else {
            this.setState({ error: true, message: apiTokenAuthError.message })
          }
        }

        if ( passwordResetSuccess && prevProps.passwordResetSuccess !== passwordResetSuccess) {
          this.setState({ error: false, message: passwordResetSuccess.detail, hideSwitch: true})
        }

    }


    renderOptions(data) {
      if (data.length < 1) {
          return;
      }
      return data["results"].map((datum, i) => {
          return (
              <option key={i} value={datum.name}>
                  {datum.name}
              </option>
          );
      });
    }

    render() {

      let { getCountriesSuccess } = this.props;

      const {activeTab, email, confirmEmail, password, country, error, message, activeLogin, hideSwitch, first_name,
        last_name, confirmPassword} = this.state;

      return (
        <div>
          <Helmet>
            <meta name="description"
                  content="Sign up to Ex UK Online for the best import deals on Vehicles, Auto Parts and Electronics and much more. " />
            <title>Reg Check -  Sign In, Create New Account</title>
          </Helmet>
          <header className="headerbg d-flex">
            <div className="container my-auto"
              style={{padding: "40px 0 40px 0"}}
            >
              { !activeLogin  &&
                <div className="row">
                  <div className="offset-1 col-10 offset-lg-4 col-lg-4" style={{ paddingLeft: "0px", paddingRight: "0px"}}>
                    <div id="search-form-div" className="container">
                      <div className="row">
                        <div className="col-12 my-4" style={{ paddingLeft: "10px", paddingRight: "10px"}}>
                          { !hideSwitch && activeTab !== "3" &&
                          <Nav tabs>
                              <NavItem>
                                  <NavLink
                                  className={classnames({ active: activeTab === '1' })}
                                  onClick={() => { this.toggle('1'); }}
                                  >
                                  Sign up
                                  </NavLink>
                              </NavItem>
                              <NavItem>
                                  <NavLink
                                  className={classnames({ active: activeTab === '2' })}
                                  onClick={() => { this.toggle('2'); }}
                                  >
                                  Sign in
                                  </NavLink>
                              </NavItem>
                          </Nav> }
                          <TabContent activeTab={activeTab} style={{marginTop:"0px", paddingTop:"30px"}}>
                              <TabPane tabId="1">
                                <div ref={this.registerRef}>
                                  {message && <Alert color={error ? "danger" : "success"}>
                                      {message}
                                  </Alert>}
                                  </div>
                                  <div style={{paddingBottom: "5px", fontWeight: 600}}>
                                    <GoogleLogin
                                      clientId={process.env.REACT_APP_G_CLIENT_ID}
                                      buttonText='Continue with Google'
                                      onSuccess={ this.socialLogin }
                                      onFailure={ this.handleSocialLoginFailure }
                                      cookiePolicy={ 'single_host_origin' }
                                      responseType='code,token'
                                    />
                                  </div>
                                  <div className="form-separator">
                                    <span>OR</span>
                                  </div>
                                  <Form onSubmit={this.handleSignup}>
                                    {this.state.multiView === 0 &&
                                      <div>
                                        <FormGroup>
                                          <Label style={{display: 'None'}} for="first_name">First Name</Label>
                                          <Input
                                            onChange={this.onChange}
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            placeholder="Enter your first name"
                                            value={first_name}
                                          />
                                        </FormGroup>
                                        <FormGroup >
                                        <Label style={{display: 'None'}} for="last_name">Last Name</Label>
                                        <Input
                                        onChange={this.onChange}
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Enter your last name"
                                        value={last_name}
                                        />
                                        </FormGroup>
                                      </div>
                                    }
                                    {this.state.multiView === 1 &&
                                      <div>
                                        <FormGroup>
                                          <Label style={{display: 'None'}} for="email">Email</Label>
                                          <Input
                                            onChange={this.onChange}
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Enter your email"
                                            value={email}
                                          />
                                        </FormGroup>
                                          <FormGroup>
                                          <Label style={{display: 'None'}} for="password">Password</Label>
                                          <Input
                                          onChange={this.onChange}
                                          type="password"
                                          name="password"
                                          id="password"
                                          placeholder="Enter a strong password"
                                          value={password}
                                          />
                                          </FormGroup>
                                          <FormGroup>
                                          <Label style={{display: 'None'}} for="confirmPassword">Confirm Password</Label>
                                          <Input
                                          onChange={this.onChange}
                                          type="Password"
                                          name="confirmPassword"
                                          id="confirmPassword"
                                          placeholder="Confirm password"
                                          value={confirmPassword}
                                          />
                                          </FormGroup>
                                      </div>
                                    }
                                    {this.state.multiView === 2 &&
                                      <div>
                                        <FormGroup>
                                          <Label style={{display: 'None'}} for="country">Delivery Postcode</Label>
                                          <Input
                                            type="text"
                                            name="country"
                                            id="country"
                                            placeholder="Enter Delivery Postcode"
                                            value={country}
                                            onChange={this.onChange}>
                                          </Input>
                                        </FormGroup>
                                        <div style={{margin: "40px 0 40px 0"}}>
                                          <FormGroup>
                                            <Input type="submit" name="signup" id="signup" className="btn btn-primary"
                                                   style={{color: 'white'}} value="Sign Up"/>
                                          </FormGroup>
                                        </div>
                                      </div>
                                    }
                                    {(this.state.multiView === 0 || this.state.multiView === 1) &&
                                      <div style={{margin: "40px 0 40px 0"}}>
                                        <Row>
                                          <Col xs={6} sm={6} md={6} lg={6}>
                                            <div>
                                              <Button
                                                disabled={this.state.multiView === 0}
                                                size="md"
                                                onClick={(e) => this.handleFormFilling(e, 'back')}
                                                className="trade-button"
                                                style={{border: "solid 1px #000000", display: "block"}}>Back
                                              </Button>
                                            </div>
                                          </Col>
                                          <Col xs={6} sm={6} md={6} lg={6}>
                                            <div style={{float: "right"}}>
                                              <Button
                                                size="md"
                                                onClick={(e) => this.handleFormFilling(e, 'next')}
                                                className="trade-button"
                                                style={{border: "solid 1px #000000", display: "block"}}>Next
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>

                                      </div>
                                    }
                                  </Form>
                                  <div  style={{paddingBottom: "10px", fontWeight: 600, paddingTop: "10px"}}>
                                    Activation email not received? <a href="/resend-activation-link" className="a-links"> Resend email </a>
                                  </div>
                                  <hr/>
                                  <div style={{padding: "16px 0px", fontWeight: 600}}>
                                    Already have an account?
                                    <Link
                                      className="a-links"
                                      to={"#"}
                                      onClick={() => { this.toggle('2'); }}>
                                      {" "}Sign in now
                                    </Link>
                                  </div>

                              </TabPane>

                              <TabPane tabId="2">
                                <div ref={this.loginRef}>
                                  {message && <Alert color={error ? "danger" : "success"}>
                                  {message}
                                  </Alert>}
                                </div>
                                <div style={{paddingBottom: "5px", fontWeight: 600}}>
                                  <GoogleLogin
                                    clientId={process.env.REACT_APP_G_CLIENT_ID}
                                    buttonText='Continue with Google'
                                    onSuccess={ this.socialLogin }
                                    onFailure={ this.handleSocialLoginFailure }
                                    cookiePolicy={ 'single_host_origin' }
                                    responseType='code,token'
                                  />
                                </div>
                                <div className="form-separator">
                                  <span>OR</span>
                                </div>
                              <Form onSubmit={this.handleSignin}>
                                  <FormGroup>
                                      <Label for="email">Email</Label>
                                      <Input
                                          onChange={this.onChange}
                                          type="email"
                                          name="email"
                                          id="email-signin"
                                          value={email}
                                      />
                                  </FormGroup>

                                  <FormGroup>
                                      <Label for="password">Password</Label>
                                      <Input
                                          onChange={this.onChange}
                                          type="password"
                                          name="password"
                                          id="password-signin"
                                          value={password}
                                      />
                                  </FormGroup>

                                  <FormGroup style={{padding: "32px 0px"}}>
                                      <Input type="submit" name="signin" id="signin" className="btn btn-primary"
                                             style={{color: "white"}} value="Sign in" />
                                  </FormGroup>
                                  </Form>
                                <div>
                                  <Link className="a-links" to={"#"} onClick={() => { this.toggle('3'); }}> Forgot password? </Link>
                                </div>
                                <hr/>
                                <div style={{paddingBottom: "10px", fontWeight: 600}}>
                                    No account? <Link className="a-links" to={"#"} onClick={() => { this.toggle('1'); }}> Create yours now </Link>
                                  </div>
                              </TabPane>

                            <TabPane tabId="3">
                               {message && <Alert color={error ? "danger" : "success"}>
                                      {message}
                                  </Alert>}
                              {!hideSwitch &&

                              <Form onSubmit={this.handlePasswordReset}>
                                <div className="col-12 page-header" style={{paddingBottom: "20px"}}>
                                  <i className="fa fa-lock" aria-hidden="true" style={{fontSize:"50px"}}></i>
                                </div>

                                <div> Enter your email address here, then check your inbox for a link to reset your password.</div>
                                <FormGroup>
                                  <Label for="email"/>
                                  <Input
                                      onChange={this.onChange}
                                      type="email"
                                      name="email"
                                      id="email-signin-reset"
                                      placeholder="Email"
                                      value={email}
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Input style={{color: "white"}} type="submit" name="signin" id="signin" className="btn btn-primary" value="Reset Password" />
                                </FormGroup>
                              </Form>

                              }
                              {hideSwitch &&
                              <div className="fat-div">
                                <div className="page-header">
                                  <i className="far fa-envelope" style={{fontSize:"50px"}}></i>
                                </div>
                                <p style={{fontWeight: "500", textAlign: "center"}}>
                                Please check your inbox for further instructions.
                                </p>
                              </div>}
                                <div>
                                  <Link className="a-links" to={"#"} onClick={() => { this.toggle('2'); }}> Go back to login screen </Link>
                                </div>
                            </TabPane>
                            </TabContent>

                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              }
              { activeLogin !== 0 ?  <Activate email={email}/> : null }
            </div>
          </header>
        </div>
      );
    }
}

export default withCookies(Login);