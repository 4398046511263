import React, { Component } from "react";
import { withRouter} from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css"
import ResetConfirmation from './ConfirmPasswordReset'
import { withCookies } from "react-cookie";
import { compose } from "redux";
import {Alert, Form, FormGroup, Input, Label } from "reactstrap";


class PasswordResetComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: "",
      token: "",
      password: "",
      confirmPassword: "",
      error: false,
      message:"",
      activeReset: 0
    };

    this.handlePasswordResetConfirm = this.handlePasswordResetConfirm.bind(this);
    this.onChange = this.onChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
  }

  componentDidUpdate(prevProps) {

    let { passwordResetConfirmSuccess, passwordResetConfirmError } = this.props
    if (passwordResetConfirmSuccess && prevProps.passwordResetConfirmSuccess !== passwordResetConfirmSuccess ) {
      this.setState({activeReset: 1})
    }

    if (passwordResetConfirmError && prevProps.passwordResetConfirmError !== passwordResetConfirmError ) {
      let message = passwordResetConfirmError
      if (message === "Invalid value") {
        message = "Password reset link invalid"
      }
      this.setState({error: true, message: message})
    }


  }

  handlePasswordResetConfirm(event) {
    const { password, confirmPassword, uid, token} = this.state;
    event.preventDefault();

    this.props.passwordResetConfirm && this.props.passwordResetConfirm({
      new_password1: password,
      new_password2: confirmPassword,
      uid: uid,
      token: token
    })
  }

  onChange(event){
    const { name, value} = event.target;

    this.setState({ [`${name}`]: value });
  };


  componentDidMount() {
    // const { postPasswordResetConfirm } = this.props;
    let uid = window.location.pathname.split('/')[4];
    let token = window.location.pathname.split('/')[5];
    // postPasswordResetConfirm(id);
    this.setState({uid: uid, token: token})
  };

  render() {

    const { password, confirmPassword, message, error, activeReset } = this.state
    return (
      <div>
        <header className="headerbg d-flex">
          <div className="container my-auto">
            {!activeReset &&
            <div className="row">
              <div className="offset-1 col-10 offset-lg-4 col-lg-4">
                <div id="search-form-div" className="container">
                  <div className="row tab-content" style={{paddingTop: '20px'}}>
                    <div className="col-12 my-4">
                      {message && <Alert color={error ? "danger" : "success"}>
                                      {message}
                                  </Alert>}
                      <Form onSubmit={this.handlePasswordResetConfirm}>
                        <FormGroup>
                          <Label for="email">New Password</Label>
                          <Input
                              onChange={this.onChange}
                              type="password"
                              name="password"
                              id="password-reset"
                              value={password}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label for="password">Confirm Password</Label>
                          <Input
                              onChange={this.onChange}
                              type="password"
                              name="confirmPassword"
                              id="password-reset-confirm"
                              value={confirmPassword}
                          />
                        </FormGroup>

                        <FormGroup>
                            <Input type="submit" name="signin" id="signin" className="btn btn-primary" value="Sign in" />
                        </FormGroup>
                      </Form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            }
            {activeReset && <ResetConfirmation /> }
          </div>
          </header>

      </div>
    );
    }
}

export default compose(
    withCookies,
    withRouter
)(PasswordResetComponent);
