import UserBasket from "./../components/Car/UserBasket";
import { connect } from "react-redux"
import {authAction} from "../store/actions";

const mapStateToProps = state => {
  const {
    authReducer: { getUserBasketSuccess, getUserBasketError, getUserBasketLoader, isLoggedIn,  applyDiscountCodeLoader,
                applyDiscountCodeSuccess, applyDiscountCodeError, getShippingQuoteSuccess,
                getShippingQuoteError, getShippingQuoteLoader, deleteBasketItemSuccess,
                deleteBasketItemError, deleteBasketLoader},
    vehicleReducer: { getShippingMethodsSuccess }
  } = state;
  return {
    getUserBasketSuccess,
    getUserBasketError,
    getUserBasketLoader,
    getShippingMethodsSuccess,
    isLoggedIn,
    applyDiscountCodeLoader,
    applyDiscountCodeSuccess,
    applyDiscountCodeError,
    getShippingQuoteSuccess,
    getShippingQuoteError,
    getShippingQuoteLoader,
    deleteBasketItemSuccess,
    deleteBasketItemError,
    deleteBasketLoader
  };
};

const mapDispatchtoProps = dispatch => {
  return {
    getUserBasket: payload =>
      dispatch(authAction.getUserBasket(payload)),
    // getBasketDetail: payload =>
    //   dispatch(authAction.getBasket)
    getShippingQuote: payload =>
      dispatch(authAction.getShippingQuote(payload)),
    deleteBasketItem: payload =>
      dispatch(authAction.deleteBasketItem(payload)),
    discountCodeApplication: payload =>
      dispatch(authAction.discountCodeApplication(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(UserBasket)