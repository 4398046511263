import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import {Helmet} from "react-helmet";

class LocalServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: "0"
    };

    this.togglings = this.togglings.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  togglings(e) {
  }

  handleSelect(e) {
    this.setState({
      activeKey : e
    });
  }

  render() {
   // const mapStyles = { width: '100%', height: '100%' };

    return (
      <div>
        <Helmet>
          <meta name="description"
                content="Visit our local offices for assistance" />
          <title>Ex UK Online - Local Customer Service</title>
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h3 className="page-header-content" style={{ color: '#b60811'}}>exUKonline offices local to you</h3>
                  <hr className="fancy-line" />
                  <br />
                  <Accordion className="accordion-body" defaultActiveKey="0" onSelect={e => this.handleSelect(e)}>
                  <Card className="accordion-header-card">
                    <Accordion.Toggle as={Card.Header} eventKey="0" onClick={e => this.togglings(e)}>
                      Zimbabwe, Harare - Joina City Mall Office
                      <div className="accordion-content-body">
                        <p>SOUTHERN BUSINESS SERVICES (PVT) LTD. Shop 106, 1st Floor Joina City Mall, Corner Julius Way and Jason Moyo, Harare, Zimbabwe </p>
                      </div>
                      <div className={"panel-title-" + (this.state.activeKey === "0" ? 'down': 'up')}>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="accordion-card-body">
                        <div className="row mt-4">
                          <div className="col-lg-4 accordion-content-body">
                            <span className="accordion-content-body-heading"> Opening Hours </span> <br />
                            Mon-Fri: 8:00AM - 4:45PM <br />
                            Sat: 9:00AM - 12:00PM <br />
                            Closed: Sunday & Public Holiday
                          </div>
                          <div className="col-lg-4">
                            <iframe width='100%' height='100%' id='mapcanvas' title="canvas1"
                                    src='https://maps.google.com/maps?q=Harare,%20Joina%20City&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
                                    frameBorder='0' scrolling='yes' marginHeight='0' marginWidth='0'>
                              <div className="zxos8_gm"><a
                                href="https://sites.google.com/site/aloeveradosage/bensons-for-beds-vouchers">Bensons</a>
                              </div>
                              <div style={{overflow: 'hidden' }}>
                                <div id='gmap_canvas' style={{ height: '100%' }}></div>
                              </div>
                              <div>
                                <small>Powered by <a href="https://www.embedgooglemap.co.uk">Embed Google Map</a>
                                </small>
                              </div>
                            </iframe>
                          </div>
                          <div className="col-lg-4 accordion-content-body">
                              <span className="accordion-content-body-heading"> Tel </span> <br />
                            +263 04 775461 <br />
                            +263 04 775461 <br />
                            +263 04 775461
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="accordion-header-card">
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      Zambia, Lusaka - River Walk Mall Office
                      <div className="accordion-content-body">
                        <p>LUSAKA ENTERPRISES (PVT) LTD. Shop 106, 1st Floor Joina City Mall, Corner Nyerere Way and Jason Kaunda, Lusaka, Zambia </p>
                      </div>
                      <div className={"panel-title-" + (this.state.activeKey === "1" ? 'down': 'up')}>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="accordion-card-body">
                        <div className="row mt-4">
                          <div className="col-lg-4 accordion-content-body">
                            <span className="accordion-content-body-heading"> Opening Hours </span> <br />
                            Mon-Fri: 8:00AM - 4:45PM <br />
                            Sat: 9:00AM - 12:00PM <br />
                            Closed: Sunday & Public Holiday
                          </div>
                          <div className="col-lg-4">
                            <iframe width='100%' height='100%' id='mapcanvas'  title="canvas2"
                                    src='https://maps.google.com/maps?q=Harare,%20Joina%20City&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
                                    frameBorder='0' scrolling='yes' marginHeight='0' marginWidth='0'>
                              <div className="zxos8_gm"><a
                                href="https://sites.google.com/site/aloeveradosage/bensons-for-beds-vouchers">Bensons</a>
                              </div>
                              <div style={{overflow: 'hidden' }}>
                                <div id='gmap_canvas' style={{ height: '100%' }}></div>
                              </div>
                              <div>
                                <small>Powered by <a href="https://www.embedgooglemap.co.uk">Embed Google Map</a>
                                </small>
                              </div>
                            </iframe>
                          </div>
                          <div className="col-lg-4 accordion-content-body">
                              <span className="accordion-content-body-heading"> Tel </span> <br />
                            +263 04 775461 <br />
                            +263 04 775461 <br />
                            +263 04 775461
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="accordion-header-card">
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      Kenya, Nairobi - Yaya centre Mall
                      <div className="accordion-content-body">
                        <p>Argwings Kodhek Rd, Nairobi, Kenya </p>
                      </div>
                      <div className={"panel-title-" + (this.state.activeKey === "2" ? 'down': 'up')}>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="accordion-card-body">
                        <div className="row mt-4">
                          <div className="col-lg-4 accordion-content-body">
                            <span className="accordion-content-body-heading"> Opening Hours </span> <br />
                            Mon-Fri: 8:00AM - 4:45PM <br />
                            Sat: 9:00AM - 12:00PM <br />
                            Closed: Sunday & Public Holiday
                          </div>
                          <div className="col-lg-4">
                            <iframe width='100%' height='100%' id='mapcanvas'  title="canvas3"
                                    src='https://maps.google.com/maps?q=Harare,%20Joina%20City&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed'
                                    frameBorder='0' scrolling='yes' marginHeight='0' marginWidth='0'>
                              <div className="zxos8_gm"><a
                                href="https://sites.google.com/site/aloeveradosage/bensons-for-beds-vouchers">Bensons</a>
                              </div>
                              <div style={{overflow: 'hidden' }}>
                                <div id='gmap_canvas' style={{ height: '100%' }}></div>
                              </div>
                              <div>
                                <small>Powered by <a href="https://www.embedgooglemap.co.uk">Embed Google Map</a>
                                </small>
                              </div>
                            </iframe>
                          </div>
                          <div className="col-lg-4 accordion-content-body">
                              <span className="accordion-content-body-heading"> Tel </span> <br />
                            +263 04 775461 <br />
                            +263 04 775461 <br />
                            +263 04 775461
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}
export default LocalServices;