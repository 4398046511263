import React from "react";
import {Button, Card, CardBody, CardImg, CardSubtitle, CardText} from "reactstrap";
import NumberFormat from "react-number-format";
import {withRouter} from "react-router-dom";

const stringSorten = str => {
  const arrayOfString = str ? str.trim().split(" ") : null;
  if (!arrayOfString) return
  if ((arrayOfString && arrayOfString[0].length > 8) || arrayOfString[0].length > 8) {
    return arrayOfString[0];
  }
  return arrayOfString[0] + " " + arrayOfString[1];
};

const routeToDetails = (props) => {
  const { history } = props;
  if (props.l) {
    history.push(`${props.d.client_url}`);
  }
  else {
    history.push('/login-c');
  }
};

const PromotionUpSellingLanding = (props) => {
   return(
     <div onClick={() => routeToDetails(props)}
          className="col-lg-4 col-md-4 col-6 mt-4"
          style={{marginBottom: '1.5rem',  cursor: 'pointer'}} key={props.i}>
       <Card>
         <CardImg
           top
           width="100%"
           className="mx-auto car-img img-responsive card-img-top-promo"
           src={
             props.d.remote_image.substr(
               0,
               props.d.remote_image.lastIndexOf('/')
             ) + '/s-l400.jpg'
           }
           alt="Card image cap"
         />
         <CardBody style={{ textAlign: 'center', paddingBottom: '0px'}}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle style={{ paddingBottom: '10px', fontSize: '13px'}}>
               {/*eslint-disable-next-line*/}
               <div className="link-text">
                 {stringSorten(props.d.title)}
               </div>{' '}
             </CardSubtitle>
             <CardText>
               {' '}
               {/*eslint-disable-next-line*/}
               <div
                 className="home-price"
                 style={{float: 'left', fontSize: '11px', color: '#999', fontWeight: 700}}
               >
                 FROM
               </div>
               <div
                 className="home-price"
                 style={{float: 'right'}}
               >
                 <NumberFormat
                   value={props.d.price}
                   displayType={'text'}
                   thousandSeparator={true}
                   prefix={'$'}
                 />
               </div>

             </CardText>
           </div>

         </CardBody>
         <div style={{
           textAlign: 'center',
           color: '#fff',
           fontSize: '12px',
           fontWeight: 900,
           backgroundColor: '#009898',
           fontFamily: 'Roboto Condensed',
           padding: '4px 6px',
         }}>
             Save: 10%
         </div>
         <div style={{
           textAlign: 'center',
           color: '#424242',
           fontSize: '12px',
           fontWeight: 900,
           backgroundColor: 'whitesmoke',
           padding: '0.5rem 0.5rem',
           fontFamily: 'Roboto Condensed'
          }}>
           <span style={{float: 'left'}}> Tel:</span> <span style={{float: 'right', fontWeight: 700}}>+447943 273454</span>
         </div>
         <div style={{paddingTop: '20px'}}>
            <Button style={{padding: '5px 15px', background: '#CB0000', fontFamily: 'Roboto Condensed'}}
                    className="delete-basket-button" size="sm">
              {props.l ? 'SEE MORE' : 'REGISTER TO BUY'}
              {/*REGISTER TO BUY*/}
            </Button>
          </div>
       </Card>
       <hr style={{width: '80%'}}/>
      </div>
    );

}

export default withRouter(PromotionUpSellingLanding);