import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux'
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { filterAction } from "../store/actions/filterCollapseAction";
import { authAction } from "../store/actions";


class BannerEmail extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      isOpen: false,
      email: "",
      redirect: false
    };
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  closeModal() {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className="campaign-banner" style={{height: '40px'}}>
        <a
          href="/flash-discount"
          onClick={this.openModal}
          className="custom-on-hover">
          <div className="row" style={{
            paddingLeft: 'calc((100vw - 1110px + 15px) / 2)',
            paddingRight: 'calc((100vw - 1110px + 15px) / 2)',
          }}>
            <div className="col-3" style={{paddingLeft: '0px', paddingTop: '5px'}}>
              <img
                src={require("../assets/single-elements/mercedes-banner-image2.png")}
                className="mx-auto car-img img-responsive"
                alt="how to buy"
                style={{maxHeight: '30px'}}
              />
            </div>
            <div className="col-6">
              <div className="campaign-banner-text blink_me"
                   style={{fontSize: '16px'}}
              >
                <span style={{fontSize: '16px', fontWeight: 'lighter', opacity: '60%'}}>*</span>GET $200 OFF
              </div>
            </div>
            <div className="col-3" style={{paddingRight: '2px', paddingLeft: '0px'}}>
              <div
                className="campaign-banner-text"
                style={{
                  backgroundColor: 'black',
                  height: '20px',
                  color: '#fff'
                }}>
                ENTER
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filterReducer: state.filterReducer,
  sideMenuReducer: state.sideMenuReducer,
  authReducer: state.authReducer,

});

const mapDispatchToProps = dispatch => {
  return {
    filterCollapseAction: payload =>
      dispatch(filterAction.setFilterCollapse(payload)),
    apiTokenLogout: payload =>
      dispatch(authAction.apiTokenLogout(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
    withCookies,
    withRouter
)(BannerEmail));
