import React, { Component } from "react";
import {Helmet} from "react-helmet";
import {dev_landing_data} from "../shared/promo_landing_data"
// import
import PromotionUpSellingLanding from "./PromotionalUpSellingLanding";
import {connect} from "react-redux";
import {compose} from "redux";
import {withCookies} from "react-cookie";
import {withRouter} from "react-router-dom";


function getJson() {
  return fetch('https://exuk-promo.s3.eu-west-2.amazonaws.com/data.json')
    .then(response => response.json())
    .catch(error => {
      console.error(error)
    })
}
class AdLanding extends Component{
  constructor(props) {
    super(props);
    this.state = {
        error: false,
        message: "",
        promoList: []
    };
  }

  componentDidMount() {
    document.body.scrollTop = 0
    window.scrollTo(0,0);
    getJson().then(list => this.setState({promoList: list}))
  }

  render(){

    const { isLoggedIn } = this.props.authReducer
    let landingData = this.state.promoList

    const fullTitle = "Ex UK Online - Deals of the day."
    const country = this.props.country
    const description = `Best Priced Offers, Import from The UK to ${country}`

    return (

       <div ref={topElement => (this.topElement = topElement)}>
          <Helmet>
            <title>{ fullTitle }</title>
            <meta name="description" content={description} />
          </Helmet>
          <div className="container text-center" id="contactContainer">
            <div className="row" style={{background: 'white'}}>
              <div className="container">
                <div>
                  <br />
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>BEST UK IMPORT DEALS</h4>
                  <hr className="fancy-line" />
                  <div style={{ marginTop: 20, minHeight: 600 }}>
                    <div className="container" style={{marginTop: '40px',
                      marginBottom: '50px'}}>
                      <div className="productDetailHeader"
                           style={{background: '#049a9a', fontWeight: 'bold', color: 'whitesmoke'}}>
                        BEST DEALS OF THE DAY
                      </div>
                      <div className="row">
                       {landingData && landingData.map((d, i) => {
                         return (
                           <PromotionUpSellingLanding d={d} i={i} l={isLoggedIn}>
                           </PromotionUpSellingLanding>
                         );
                       })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = state => ({
  authReducer: state.authReducer,

});

export default connect(
  mapStateToProps,
)(compose(
    withCookies,
    withRouter
)(AdLanding));