import React from "react";

function EarnedPositionComponent(props) {
  return <div>
    <div className="container">
      <table className="table table-striped">
        <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
        <tr>
          <th>Earned Position</th>
          <th>Fee</th>
          <th>Special Condition</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="2">
            Independent Business Owner
          </td>
          <td>2.5%</td>
          <td>Direct Referrals</td>
        </tr>
        <tr>
          <td>2.0%</td>
          <td>Downline Referrals</td>
        </tr>
        <tr>
          <td style={{fontWeight: 'bold', backgroundColor: 'white', borderRight: '1px solid #dee2e6'}}
              rowSpan="2">Executive Team Manager
          </td>
          <td>3.0%</td>
          <td>Direct Referrals</td>
        </tr>
        <tr>
          <td>2.0%</td>
          <td>Downline Referrals</td>
        </tr>

        <tr>
          <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="2">
            Executive Team Leader
          </td>
          <td>4%</td>
          <td>Direct Referrals</td>
        </tr>
        <tr>
          <td>2.0%</td>
          <td>Downline Referrals</td>
        </tr>
        <tr>
          <td style={{fontWeight: 'bold', backgroundColor: 'white', borderRight: '1px solid #dee2e6'}}
              rowSpan="2">Senior Executive Leader
          </td>
          <td>4.5%</td>
          <td>Direct Referrals</td>
        </tr>
        <tr style={{borderBottom: '1px solid #dee2e6'}}>
          <td>2.0%</td>
          <td>Downline Referrals</td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>;
}
export default EarnedPositionComponent;