import React, {Component} from "react";
import { prodOptionsData} from "../shared/promo_landing_data";
import LazyLoad from "react-lazyload";
import PromoElements from "./PromoElements";

class HowItWorks extends Component {
  render() {
    return(
      <div style={{ backgroundColor: 'white', width: '100%' }}>
        <div className="col-lg-6 col-sm-12 offset-lg-3">
          <div className="headings-div-successful" style={{ textAlign: 'center', paddingTop: '15px' }}>
            <h2 className="headings">How It Works</h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {prodOptionsData && prodOptionsData.map((d, i) => {
              return (
                <LazyLoad height={200} offset={100} once >
                  <PromoElements d={d} i={i}>
                  </PromoElements>
                </LazyLoad>
              );
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default HowItWorks;

