import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

class HelpContact extends Component{
  render(){
    return(
      <div>
        <Helmet>
          <meta name="description"
                content="Find answers to your buying, importing, account questions. Contact us for more help." />
          <title>Ex UK Online - Customer Service</title>
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">

              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>Hello. What can we help you with?</h4>
                  <hr className="fancy-line" />
                  <br />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading">
                   </div>
                  </div>
                  <br />
                  <br />
                  <p>
                    <h5>Browse Help Topics</h5>
                  </p>
                  <hr />
                </div>
              </div>

           </div>
           </div>
           <div className="row help-row">
             <div className="col-lg-12">
               <div className="row mt-4 bottom-out-space">
                 <div className="col-lg-3 help-cards">
                   <Link to="/how-to-buy">
                     <div className="inner-help-cards" style={{height: "100px"}}>
                     <div className="a-column">
                       <img
                         src={require("../assets/single-elements/exyookay-how-to-edited.png")}
                         className="help-cards-img"
                         alt="how to buy itrem"
                       />
                     </div>
                     <div className="b-column">
                       <h6 className="inner-help-heading"> Pre-owned Vehicles</h6>
                       <ul className="help-cards-list">
                         <li>
                           How to buy a vehicle
                         </li>
                         <li>
                           How to pay
                         </li>
                       </ul>
                     </div>
                   </div>
                   </Link>
                 </div>
                 <div className="col-lg-3 help-cards">
                   <Link to="/bulk-stock-procurement">
                     <div className="inner-help-cards" style={{height: "100px"}}>
                     <div className="a-column">
                       <img
                         src={require("../assets/single-elements/exyookay-parts-online-edited.png")}
                         className="help-cards-img"
                         alt="how to buy"
                       />
                     </div>
                     <div className="b-column">
                       <h6 className="inner-help-heading"> Stock Procurement </h6>
                       <ul className="help-cards-list">
                         <li>
                           How to buy in bulk
                         </li>
                         <li>
                           Available shipping methods
                         </li>
                       </ul>
                     </div>
                   </div>
                   </Link>
                 </div>
                 <div className="col-lg-3 help-cards">
                   <Link to="/about-us">
                     <div className="inner-help-cards" style={{height: "100px"}}>
                     <div className="a-column">
                       <img
                         src={require("../assets/single-elements/exyookay-about-us-edited.png")}
                         className="help-cards-img"
                         alt="how to buy"
                       />
                     </div>
                     <div className="b-column">
                       <h6 className="inner-help-heading">About us</h6>
                       <ul className="help-cards-list">
                         <li>
                           Overview
                         </li>
                         <li>
                           Bank Information
                         </li>
                       </ul>
                     </div>
                   </div>
                   </Link>
                 </div>
                 <div className="col-lg-3 help-cards">
                   <Link to="/supporter-programme/home">
                     <div className="inner-help-cards" style={{height: "100px"}}>
                       <div className="a-column">
                         <img
                           src={require("../assets/single-elements/exyookay-about-us-edited.png")}
                           className="help-cards-img"
                           alt="Ex UK Online Supporter Programme"
                         />
                       </div>
                       <div className="b-column">
                         <h6 className="inner-help-heading">Supporter Programme</h6>
                         <ul className="help-cards-list">
                           <li>
                             About Ex UK Online Supporter Programme
                           </li>
                         </ul>
                       </div>
                     </div>
                   </Link>
                 </div>
               </div>
             </div>
           </div>
          </div>
        </section>
      </div>
      );
    }
}
export default HelpContact;
