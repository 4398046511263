import reportNotFoundImg from '../../assets/images/report-not-found.jpg';
import React, { Component, createRef } from "react";
import { Loading } from "../LoadingComponent";
import { formatDate } from "../helpers/logic";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Link, Route, Switch} from "react-router-dom";
import {
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  Form,
  Card,
  BreadcrumbItem, Breadcrumb, Button
} from "reactstrap";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Typography from '@material-ui/core/Typography'
import 'react-sliding-pane/dist/react-sliding-pane.css';

import SlidingPane from "react-sliding-pane";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import credentials from "../../config/credentials";
import MotComponent from "./MotComponent";
import VehicleReport from "./FreeReportComponent"

class SearchItem extends Component {
  constructor(props) {
    super(props);
    this.targetRef = createRef();
    this.state = {
      width: null,
      results: props.searchItemSuccess && props.searchItemSuccess.metadata,
      searchItemSuccess:
        props.searchItemSuccess && props.searchItemSuccess,
      currentPage: 1,
      query: "",
      sortOrder: "",
      pageNumbers: [],
      loading: false,
      dropdownOpen: false,
      makeSelected: this.props.url.sv,
      modelSelected: this.props.url.svm,
      minPrice: 0,
      maxPrice: 0,
      minValue: 0,
      maxValue: 0,
      sorted: "0",
      resultsMetadata: {},
      activePageNumber: 1,
      totalItemsEntries: 0,
      itemsPerPage: 0,
      totalPages: 1,
      isImported: '',
      isImportedGood: false,
      isFinanced: true,
      isFinancedGood: false,
      isStolen: true,
      isStolenGood: false,
      isScrapped: true,
      isScrappedGood: false,
      isWrittenOff: true,
      isWrittenOffGood: false,
      isMOT: false,
      isMOTGood: false,
      isMOTExempt: false,
      motMetaData: {},
      vedMetaData: {},
      isTaxed: false,
      isTaxedGood: false,
      premiumReport: false,
      isFilterPaneOpen: false,
      reportFound: false,
      values: [
         0,
        100,
        500,
        1000,
        1500,
        2000,
        2500,
        3000,
        3500,
        4000,
        4500,
        5000,
        5500,
        6000,
        6500,
        7000,
        7500,
        8000,
        8500,
        9000,
        9500,
        10000,
        11000,
        12000,
        13000,
        14000,
        15000,
        16000,
        17000,
        18000,
        19000,
        20000,
        22500,
        25000,
        27500,
        30000,
        35000,
        40000,
        45000,
        50000,
        55000,
        60000,
        65000,
        70000,
        75000,
        150000
      ]
    };

    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.handlePreviousForward = this.handlePreviousForward.bind(this);
    this.toggle = this.toggle.bind(this);
    this.keyToOption = this.keyToOption.bind(this);
    this.handleMakeChange = this.handleMakeChange.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePriceRange = this.handlePriceRange.bind(this)
    this.handleMax = this.handleMax.bind(this);
    this.sortAscending = this.sortAscending.bind(this);
    this.sortDescending = this.sortDescending.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.paginate = this.paginate.bind(this);
    this.openFilterPanel = this.openFilterPanel.bind(this);
    this.handleFilterChanges = this.handleFilterChanges.bind(this);
    this.evaluateReport = this.evaluateReport.bind(this);
    this.printDocument = this.printDocument.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleConversion = this.handleConversion.bind(this);
    this.handleNoReportFound = this.handleNoReportFound.bind(this);
  }

  componentDidMount() {

    if (this.props.isLoggedIn) {
      const token = localStorage.getItem('token')
      let userSearchQuery = {q: this.props.match.params.q}
      if (userSearchQuery['q'] === "cat-search") {
        userSearchQuery = {q: this.props.match.params.type}
      }
      const fetchLogSearchQuery = fetch(`${credentials.API_URL}/analytics/`, {
        method: 'POST',
        headers: {'Content-Type':'application/json',
                  'Authorization': `token ${token}`},
        body: JSON.stringify(userSearchQuery)
      })
      fetchLogSearchQuery.then(response => {
      return response;
      })
    }

    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.props.sideMenuAction(true);
    let upc = this.props.match.params.upc
    let query = window.location.pathname.split('/')[2]
    let isPremium = this.props.match.params.p === 'premium'
    this.setState({ query: query, premiumReport: isPremium})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.props.sideMenuAction(false);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  printDocument() {
    const input = document.getElementById('divToPrint');

    html2canvas(input, {
      scale: 2, // Increase scale for better resolution
      useCORS: true, // Handle CORS if necessary
      allowTaint: true, // Allow cross-origin images
      logging: true,
    })
      .then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png'); // Convert the canvas to a data URL
        link.download = `${this.props.match.params.q}-report-download.png`; // Set the filename
        link.click(); // Trigger the download
      })
      .catch((err) => {
        console.error('Error taking screenshot:', err);
      });
  }

  evaluateReport() {
    const importedResult = this.state.isImported === false
    const financeResult = this.state.isFinanced === 0
    const stolenResult = this.state.isStolen !== true
    const scrappedResult = this.state.isScrapped !== true
    const writtenOffResult = this.state.isWrittenOff !== true
    const motResult = this.state.isMOT === true
    const taxResult = this.state.isTaxed === true

    this.setState({
      isImportedGood: importedResult,
      isFinancedGood: financeResult,
      isStolenGood: stolenResult,
      isScrappedGood: scrappedResult,
      isWrittenOffGood: writtenOffResult,
      isMOTGood: motResult,
      isTaxedGood: taxResult
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // window.scrollTo(0, this.topElement.offsetTop);
    if (this.props.searchResultsMetadata && prevProps.searchResultsMetadata !== this.props.searchResultsMetadata) {

      this.setState({
        activePageNumber: parseInt(this.props.searchResultsMetadata.pageNumber),
        totalItemsEntries: parseInt(this.props.searchResultsMetadata.totalEntries),
        itemsPerPage: parseInt(this.props.searchResultsMetadata.entriesPerPage),
        totalPages: parseInt(this.props.searchResultsMetadata.totalPages),
      })
    }
    if (this.props.url && prevProps.url !== this.props.url) {
      let priceFrom = this.props.url.min
      let priceTo = this.props.url.max
      let sortOrder = this.props.url.sort
      let pageNum = this.props.url.page
      this.setState({minPrice: priceFrom, maxPrice: priceTo, sortOrder: sortOrder, activePageNumber: pageNum})
    }
    if (
      prevState.searchItemSuccess !== this.state.searchItemSuccess &&
      this.state.searchItemSuccess
    ) {
      this.props.getAllProducts("check")
      // Call evaluateReport when searchItemSuccess has been updated and populated
      this.evaluateReport();
    }
    // Failure case: When no report is found
    if (this.props.searchItemError && prevProps.searchItemError !== this.props.searchItemError) {
      this.handleNoReportFound()
    }
    // if add to cart has been successfull then merge
    let {mergeCartSuccess, cartProducts, history, cartProductsReduced} = this.props;
    if (cartProductsReduced && prevProps.cartProductsReduced !== cartProductsReduced) {
      this.props.mergeCart && this.props.mergeCart({
        itemsToAdd: JSON.stringify(cartProducts)
      })
    }

    if (mergeCartSuccess && prevProps.mergeCartSuccess !== mergeCartSuccess) {
      const checkoutURL = `/guest-checkout?reg=${this.state.query}`
      history.push(checkoutURL)
    }
  }

  handleNavHome = () => {
    this.props.history.push('/');
  };

  openFilterPanel = (e) => {
    this.setState({isFilterPaneOpen: true})
  }

  handleFilterChanges = (e) => {

    const pNum = this.state.activePageNumber
    const target = e.currentTarget.textContent
    let payload = this.props.url

    let urlParams = ("type" in payload)
      ?
      `/partner-one-search-cat/${payload.q}/${payload.min}/${payload.max}/${pNum}`
      :
      `/partner-one-search/${payload.q}/${payload.min}/${payload.max}/${pNum}`

    if (target === "Price: High to Low"){
      urlParams = `${urlParams}/d`
    }
    if (target === "Price: Low to High"){
      urlParams = `${urlParams}/a`
    }
    if (target === "Best Match"){
      urlParams = `${urlParams}/b`
    }
    if ("type" in payload) {
      urlParams = `${urlParams}/${payload.type}`
    }
    this.props.history.push(urlParams)
  }

  handlePageChange (pageNumber) {

    let pNum = pageNumber.toString()
    let payload = this.props.url

    let url = ("type" in payload)
      ?
      `/partner-one-search-cat/${payload.q}/${payload.min}/${payload.max}/${pNum}/${this.state.sortOrder}/${payload.type}`
      :
      `/partner-one-search/${payload.q}/${payload.min}/${payload.max}/${pNum}/${this.state.sortOrder}`

    this.props.history.push(url)
  }

  handleChange = e => {
    this.setState({ value: e.target.value });
  };

  handleMakeChange(event) {
    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${event.target.value}/${this.props.url.svm}/${this.state.sorted}`;

    this.props.history.push(`/search/${query}`);
    this.setState({ makeSelected: event.target.value });
  }

  handleModelChange(event) {
    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${this.state.makeSelected}/${event.target.value}/${this.state.sorted}`;
    this.props.history.push(`/search/${query}`);

    this.setState({ modelSelected: event.target.value });
  }

  handlePriceRange(event) {
    const selectPrice = event.target.name
    let q = this.state.query
    let maxPrice = this.state.maxPrice
    let pageNum = this.state.activePageNumber
    let sortOrder = this.state.sortOrder
    let min  = this.state.minPrice
    let payload = this.props.url
    let urlParams = `/partner-one-search/${q}/${min}/${maxPrice}/${pageNum}/${sortOrder}`

    switch (selectPrice) {
      case 'min-price':
        min = event.target.value
        urlParams = ("type" in payload) ?
          `/partner-one-search-cat/${payload.q}/${min}/${payload.max}/${pageNum}/${sortOrder}/${payload.type}`
            :
          `/partner-one-search/${q}/${min}/${maxPrice}/${pageNum}/${sortOrder}`
        this.props.history.push(urlParams)
        break;

      case 'max-price':
        maxPrice = event.target.value
        urlParams = ("type" in payload) ?
          `/partner-one-search-cat/${payload.q}/${payload.min}/${maxPrice}/${pageNum}/${sortOrder}/${payload.type}`
            :
          `/partner-one-search/${q}/${min}/${maxPrice}/${pageNum}/${sortOrder}`
        this.props.history.push(urlParams)
        break;

      default:
        break;
    }
  }

  handleMax(event) {
    const query = `${this.props.url.page}/${this.state.minValue}/${
      event.target.value
    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}`;

    this.props.history.push(`/search/${query}`);

    this.setState({ maxValue: event.target.value });
  }

  handleNavigation(event) {
    try {
      let {history, getAllProductsSuccess} = this.props;
      let vrm = this.props.match.params.q
      const baseURL = credentials.API_URL
      let match = getAllProductsSuccess.filter(hit => hit['title'].toLowerCase() === vrm.toLowerCase());
      if (match.length > 0) {
        let vrmId = match[0].id
        let url = baseURL + "/products/" + vrmId + "/"
        const payload = {
          prodId: vrmId,
          prodTitle: match[0].title,
          prodPrice: 8.99,
          numItems: 1,
          mainImage: 'https://i.ibb.co/0VBPvGk/text4533.png'
        }
        const {cartProducts} = this.props;
        window.scrollTo({top: 0, behavior: 'smooth'})
        this.props.addCartProduct(payload)

      }
      this.handleConversion();
    } catch (error) {
      const userId = `System - Failed on Full Report - ${this.props.match.params.q}`
      this.sendRequest(process.env.REACT_APP_EMAILJS_TEMPLATEID, userId, "admin@reg-check-mate.co.uk",
        process.env.REACT_APP_EMAILJS_RECEIVER, "Failure on Get Full Report", "07001234567")
    }
  }

  sendRequest(templateId, userName, userEmail, receiverEmail, userRequest, contactNumber) {
    window.emailjs.send(
      'sendgrid',
      templateId,
      {
        userName,
        userEmail,
        receiverEmail,
        contactNumber,
        userRequest
      })
      .catch(err => console.error('Failed to send feedback. Error: ', err))
  }

  handleConversion() {
      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-972829596/vmZiCLbSotoZEJzn8M8D'
        });
      }
    }

  handleNoReportFound() {
    const userId = `System - Report Not Found - ${this.props.match.params.q}`
    this.sendRequest(process.env.REACT_APP_EMAILJS_TEMPLATEID, userId, "admin@reg-check-mate.co.uk",
      process.env.REACT_APP_EMAILJS_RECEIVER, "Report Not Found", "07001234567")
  }

  handleBreadCrumb(type, value) {
    let query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/any`;

    if (type === "class") {
      query = `1/0/0/${this.props.url.st}/*/*/${this.state.sorted}`;
    }
    if (type === "model") {
      query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/*/${this.state.sorted}`;
    }


    this.props.history.push(`/search/${query}`);
  }

  keyToOption(key) {
    return key
      .split("-")
      .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
      .join(" ");
  }


  static getDerivedStateFromProps(props, state) {
    const { searchItemSuccess } = props;
    // Logic for displaying limited searched Vehicles
    if (searchItemSuccess) {
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(searchItemSuccess.count / 25); i++) {
        pageNumbers.push(i);
      }
      return {
        results: props.searchItemSuccess.count,
        searchItemSuccess: searchItemSuccess.DataItems,
        isImported: searchItemSuccess.DataItems.VehicleRegistration.Imported,
        isFinanced: searchItemSuccess.DataItems.FinanceRecordCount,
        reportFound: searchItemSuccess.StatusCode === "Success",
        isStolen: searchItemSuccess.DataItems.Stolen,
        isScrapped: searchItemSuccess.DataItems.Scrapped,
        isWrittenOff: searchItemSuccess.DataItems.WrittenOff,
        isMOT: searchItemSuccess.DataItems.VehicleStatus.VehicleHasCurrentMot,
        isMOTExempt: searchItemSuccess.DataItems.VehicleStatus.VehicleIsMotExempt,
        isTaxed: searchItemSuccess.DataItems.VehicleStatus.MotVed.VedCurrentlyValid,
        motMetaData: {
          "Valid Until": searchItemSuccess.DataItems.VehicleStatus.NextMotDueDate,
          "Days Until Next Due": searchItemSuccess.DataItems.VehicleStatus.DaysUntilNextMotIsDue
        },
        vedMetaData: {
          "Valid Until": searchItemSuccess.DataItems.VehicleStatus.MotVed.VedExpiryDate,
          "Tax Days Remaining": searchItemSuccess.DataItems.VehicleStatus.MotVed.VedDaysRemaining,
          "Sorn In Place": searchItemSuccess.DataItems.VehicleStatus.MotVed.IsVehicleSorn ? "Yes" : "No"
        },
        pageNumbers
      };
    }
    return null;
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sortAscending() {
    if (this.state.sorted === "0") {
      return
    }

    const query = `${this.props.url.page}/${this.props.url.min}/${
      this.props.url.max
    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}/0`;

    this.props.history.push(`/search/${query}`);
    this.setState({ sorted: "0"  });
  }

  sortDescending() {
    if (this.state.sorted === "1") {
      return
    }
    const query = `${this.props.url.page}/${this.props.url.min}/${this.props.url.max
                    }/${this.props.url.st}/${this.props.url.sv}/${this.props.url.svm}/1`;

    this.props.history.push(`/search/${query}`);
    this.setState({ sorted: "1"  });

  }

  routeToDetails = (id, price) => {
    const { history } = this.props;
    history.push(`/item-details/${id}/${price}`);
  };

  renderResults(currentResult) {
    if (currentResult && currentResult) {
      let vehImage = 'https://idaily-img.s3.eu-west-2.amazonaws.com/m-1u-05-09-24-11-26-29-exam-revision.jpg'
      const {
        Vrm,
        Make,
        Model,
        Colour,
        YearOfManufacture,
        Imported,
        WriteOffCategory,
        TransmissionType,
        EngineCapacity,
        GearCount,
        FuelType,
        VehicleImages: { ImageDetailsCount, ImageDetailsList },
        TechnicalDetails: { Performance, General, Dimensions },
        SmmtDetails: { BodyStyle },
        VehicleRegistration: {
          EngineNumber, DateFirstRegistered, VinLast5, VehicleClass, SeatingCapacity, DoorPlan, Co2Emissions
        },
        VehicleHistory: { NumberOfPreviousKeepers, V5CCertificateCount, PlateChangeCount, PlateChangeList },
        MotHistory: { RecordCount, RecordList },
        VehicleStatus: { MotVed }
      } = currentResult;
      const { Power: { Bhp } } = Performance;
      const { KerbWeight, NumberOfDoors } = Dimensions;
      const { EuroStatus, DrivingAxle } = General;
      const { VedCurrentlyValid, VedExpiryDate, VedDaysRemaining, MotSornInPlace } = MotVed;
      if (ImageDetailsCount > 0) {
        vehImage = ImageDetailsList[0]["ImageUrl"]
      }
      const formattedDateRegistered = formatDate(DateFirstRegistered);
      const { isImportedGood, isFinancedGood, isStolenGood, isScrappedGood,
        isWrittenOffGood, isMOTGood, motMetaData, isTaxedGood, vedMetaData } = this.state
      const furtherDetailArr = [
        {'title': 'First Registered', 'value': formattedDateRegistered},
        {'title': 'Number of Previous Keepers', 'value': NumberOfPreviousKeepers === 0 ? 1 : NumberOfPreviousKeepers},
        {'title': 'V5 Certificates', 'value': V5CCertificateCount},
        {'title': 'Vehicle Class', 'value':  VehicleClass },
        {'title': 'Vin Last 5', 'value':  VinLast5 },
        {'title': 'Door Plan', 'value':  DoorPlan },
        {'title': 'CO2 Emission', 'value':  `${Co2Emissions} g/km` },
        {'title': 'EuroStatus', 'value':  EuroStatus },
        {'title': 'Driven Axle', 'value':  DrivingAxle },
        {'title': 'Kerb Weight', 'value':  KerbWeight },
        {'title': 'Seating Capacity', 'value':  SeatingCapacity },
        {'title': 'Number Of Doors', 'value':  NumberOfDoors },
      ]
      const genVehInfoArr = [
        {'title': 'Make', 'value': Make},
        {'title': 'Model', 'value': Model},
        {'title': 'Colour', 'value': Colour},
        {'title': 'Transmission', 'value': TransmissionType},
        {'title': 'Gear Count', 'value': GearCount},
        {'title': 'Engine Size', 'value': EngineCapacity},
        {'title': 'BHP', 'value': Bhp},
        {'title': 'Body Type', 'value': BodyStyle},
        {'title': 'Fuel Type', 'value': FuelType},
        {'title': 'Engine Number', 'value': EngineNumber},
      ]

      return (
        <div id="divToPrint" style={{paddingBottom: '40px', marginBottom: '40px'}}>
          <Row style={{textAlign: 'center', fontWeight: 'bolder', paddingBottom: "40px"}}>
            <Col>
              <div>
                <Typography variant="h6" className="report-headers" >
                  Your <span className="premium">premium</span> vehicle report
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1" className="typo-text">
                  <span className="report-plate"> {Vrm} </span>
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1" className="typo-text">
                  { Make } { Model } ({YearOfManufacture}) in { Colour }
                </Typography>
              </div>
            </Col>
            <Col>
              <div>
                <img
                  style={{height: '200px', paddingTop: '10px', paddingBottom: '15px'}}
                  src={vehImage}
                  alt="car image"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className={isTaxedGood ? "summary-boxes-success": "summary-boxes-failure"}>
                <Col className="tax-mot-headers">
                  <Typography variant="h6" className="tax-mot-header-text" gutterBottom>
                    TAX
                  </Typography>
                </Col>
                <Col className="tax-mot-summary">
                  { isTaxedGood ?
                    <CheckCircleRoundedIcon style={{fill: "#22cc88", fontSize: "2rem"}}/>
                    :
                    <CancelRoundedIcon style={{fill: "#d77474", fontSize: "2rem"}}/>
                  }
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className={isMOTGood ? "summary-boxes-success": "summary-boxes-failure"}>
                <Col className="tax-mot-headers">
                  <Typography variant="h6" className="tax-mot-header-text" gutterBottom>
                    MOT
                  </Typography>
                </Col>
                <Col className="tax-mot-summary">
                  { isMOTGood ?
                    <CheckCircleRoundedIcon style={{fill: "#22cc88", fontSize: "2rem"}}/>
                    :
                    <CancelRoundedIcon style={{fill: "#d77474", fontSize: "2rem"}}/>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ fontWeight: 800, paddingBottom: "64px"}}>
            <Col>
              <div>
                <Typography variant="h6" className="report-headers" gutterBottom>
                  Checklist
                </Typography>

                {/*Taxed*/}
                <div className="report-div">
                  <Row className={isTaxedGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        Tax
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1">
                        {isTaxedGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isTaxedGood ? "Yes": "No" }
                      </Typography>
                      { Object.entries(vedMetaData).map(([key, value]) => {
                        if (value !== null) {
                          return (
                            <div className="report-meta" key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          );
                        }
                        return null;
                      })}
                    </Col>
                  </Row>
                </div>

                {/*Imported*/}
                <div className="report-div">
                  <Row className={isImportedGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        Imported
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1">
                        {isImportedGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isImportedGood ? "No": "Yes" }
                      </Typography>
                    </Col>
                  </Row>
                </div>

                {/*Financed*/}
                <div className="report-div">
                  <Row className={isFinancedGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        Finance Outstanding
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1">
                        { isFinancedGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isFinancedGood ? "No": "Yes" }
                      </Typography>
                    </Col>
                  </Row>
                </div>

                {/*Stolen*/}
                <div className="report-div">
                  <Row className={isStolenGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        Stolen
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1">
                        { isStolenGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isStolenGood ? "No": "Yes" }
                      </Typography>
                    </Col>
                  </Row>
                </div>

                {/*Scrapped*/}
                <div className="report-div">
                  <Row className={isScrappedGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        Scrapped
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1">
                        { isScrappedGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isScrappedGood ? "No": "Yes" }
                      </Typography>
                    </Col>
                  </Row>
                </div>

                {/*Written Off*/}
                <div className="report-div">
                  <Row className={isWrittenOffGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        Written Off
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      <Typography variant="body1">
                        { isWrittenOffGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isWrittenOffGood ? "No": "Yes" }
                      </Typography>
                      { !isWrittenOffGood ?
                        <Typography variant="body1">
                          { WriteOffCategory }
                        </Typography>: null
                      }
                    </Col>
                  </Row>
                </div>

                {/*MOT*/}
                <div className="report-div">
                  <Row className={isMOTGood ? "success-report" : "failure-report"}>
                    <Col className="key-data">
                      <Typography variant="body1">
                        MOT
                      </Typography>
                    </Col>
                    <Col className="value-data">
                      { isMOTGood ? <CheckRoundedIcon/> : <ClearRoundedIcon/>} { isMOTGood ? "Yes": "No" }
                      {Object.entries(motMetaData).map(([key, value]) => (
                        <div className="report-meta" key={key}>
                          <strong>{key}:</strong> {value}
                        </div>
                      ))}
                    </Col>
                  </Row>
                </div>



              </div>
            </Col>
            <Col>
              <Typography variant="h6" className="report-headers" gutterBottom>
                General Vehicle Info
              </Typography>
              <div className="report-meta">
                <Typography variant="subtitle2" gutterBottom>
                  You should carefully check each value below to make sure that is corresponds to the vehicle
                </Typography>
              </div>

              {genVehInfoArr && genVehInfoArr.map((obj, idx) => (
                obj.value !== null && (
                  <div>
                    <Row key={idx} >
                      <Col className="key-data">
                        <Typography variant="body1" gutterBottom>
                          {obj.title}
                        </Typography>
                      </Col>
                      <Col className="value-data">
                        <Typography variant="body1" gutterBottom>
                          {obj.value}
                        </Typography>
                      </Col>
                    </Row>
                    <hr className="hr-data"/>
                  </div>
                )
              ))}

            </Col>
          </Row>
          <Row>
            {/*MOT History*/}
            <Col>
              <div>
                {/*Header*/}
                <Typography variant="h6"  className="report-headers" gutterBottom>
                  MOT History
                </Typography>
              </div>
              <Row>
                <Col>
                  <Typography variant="body1">
                    MOT Count
                  </Typography>
                </Col>
                <Col>
                  <Typography variant="body1">
                    {RecordCount}
                  </Typography>
                </Col>
              </Row>
              <Row>
                {RecordList && RecordList.map((d, i) => {
                  return (
                    <div key={i} className="mot-component-container">
                      <MotComponent d={d} i={i} />
                    </div>
                  );
                })}
              </Row>

            </Col>

            {/*Further Vehicle Checks*/}
            <Col>
              <div>
                {/*Header*/}
                <Typography variant="h6" className="report-headers" gutterBottom>
                  Further Vehicle Checks
                </Typography>
              </div>
              {furtherDetailArr && furtherDetailArr.map((obj, index) => (
                obj.value !== null && (
                  <div>
                    <Row key={index}>
                      <Col className="key-data">
                        <Typography variant="body1" gutterBottom>
                          {obj.title}
                        </Typography>
                      </Col>
                      <Col className="value-data">
                        <Typography variant="body1" gutterBottom>
                          {obj.value}
                        </Typography>
                      </Col>
                    </Row>
                    <hr className="hr-data"/>
                  </div>

                )
              ))}

              {/*No of plate change*/}
              <Row>
                <Col className="key-data">
                  <Typography variant="body1" gutterBottom>
                    No of Plate Change(s)
                  </Typography>
                </Col>
                <Col className="value-data">
                  <Typography variant="body1" gutterBottom>
                    { PlateChangeCount }
                  </Typography>
                  <div>
                    {PlateChangeList && PlateChangeList.map(change => {
                      return(
                        <div>
                          <Typography variant="body1" gutterBottom>
                            From: <span className="report-plate"> {change.PreviousVRM} </span> &nbsp;To: <span className="report-plate"> { change.CurrentVRM } </span>
                          </Typography>
                        </div>
                      )
                    })}
                  </div>
                </Col>
              </Row>
              {/*TODO if plate changes (list them here)*/}
            </Col>
          </Row>
        </div>
      )
    } else {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12">
          <Card>No record Found</Card>
        </div>
      );
    }
  }

  handlePaginationClick(number) {
    const query = `${number}/${this.props.url.min}/${this.props.url.max}/${
      this.props.url.st
    }/${this.props.url.sv}/${this.props.url.svm}/${this.state.sorted}`;

    this.props.history.push(`/search/${query}`);

    this.setState({
      currentPage: number
    });
  }

  handlePreviousForward(number, switchState) {
    const reqNumber = switchState ? this.state.currentPage + 1 : this.state.currentPage - 1
    const query = `${reqNumber}/${this.props.url.min}/${this.props.url.max}/${
      this.props.url.st
    }/${this.props.url.sv}/${this.props.url.svm}/${this.state.sorted}`;
    this.props.history.push(`/search/${query}`);
    if (switchState) {
      this.setState({
        currentPage: this.state.currentPage + number
      });
    } else {
      this.setState({
        currentPage: this.state.currentPage - number
      });
    }
  }

  paginate(array, page_size, page_number) {
    --page_number
    return array.slice(page_number, (page_number + page_size));
  }

  render() {

    const selectMinValues = this.state.values
      .filter((val) => {
        return val < this.state.maxPrice
      })
      .map((val, i) => {
      return (
        <option key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const selectMaxValues = this.state.values
      .filter((val) => {
        return val > this.state.minPrice
      })
      .map((val, i) => {
      return (
        <option key={val + i} value={val}>
          {val}
        </option>
      );
    });

    const { searchItemLoader } = this.props;

    const { searchItemSuccess, query, reportFound } = this.state;

    return (

      <div ref={topElement => (this.topElement = topElement)}>
        <div className="container text-center" id="contactContainer">
          <div className="row">
            {searchItemLoader ? (
              <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '500px'}}>
                <Loading />
              </div>
            ) : (
              <div className="container" style={{ paddingBottom: '160px' }}>
                <Breadcrumb style={{marginBottom: "10px", borderBottom: "1px solid #DDD"}}>
                  <BreadcrumbItem>
                    <Link to="/" className="listedLink">
                      Home
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link
                      to="#"
                      className="listedLink"
                      // onClick={() => this.handleBreadCrumb("class", url.st)}
                    >
                        { decodeURI(query) }
                      </Link>{" "}
                  </BreadcrumbItem>
                </Breadcrumb>
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <h6 className="text-left">
                      <b className="resultBold">
                        <Typography variant="body1">
                          { reportFound ? '1' : 'No' } Report found
                        </Typography>
                      </b>
                    </h6>
                    </div>
                    <div className="col" style={{ textAlign: "right" }}>
                      {this.state.reportFound ? (
                        this.state.premiumReport ? (
                          <Button
                            size="sm"
                            onClick={this.printDocument}
                            className="shop-now"
                            style={{ border: "solid 1px #000000" }}
                          >
                            Download Report
                          </Button>
                        ) : (
                          <Button
                            size="sm"
                            onClick={this.handleNavigation}
                            className="shop-now"
                            style={{ border: "solid 1px #000000" }}
                          >
                            Get Full Check
                          </Button>
                        )
                      ) : (
                        <Button
                          className="shop-now"
                          onClick={this.handleNavHome}
                          style={{ border: "solid 1px #000000", margin: "0px" }}
                        >
                          Search Again
                        </Button>
                      )}
                    </div>
                  </div>
                  {this.state.loading && (
                    <Loading message="Generating Report..." height="10vh" />
                  )}
                </div>
                <hr />

                {reportFound && searchItemSuccess ? (
                  this.state.premiumReport ? (
                    this.renderResults(searchItemSuccess)
                  ) : (
                    <VehicleReport
                      currentResult={searchItemSuccess}
                      isImportedGood={this.state.isImportedGood}
                      isFinancedGood={this.state.isFinancedGood}
                      isStolenGood={this.state.isStolenGood}
                      isScrappedGood={this.state.isScrappedGood}
                      isWrittenOffGood={this.state.isWrittenOffGood}
                      isMOTGood={this.state.isMOTGood}
                      motMetaData={this.state.motMetaData}
                      isTaxedGood={this.state.isTaxedGood}
                      vedMetaData={this.state.vedMetaData}
                    />
                  )
                ) : (
                  <div className="no-report">
                    <p>
                      <Typography variant="h5">
                        No report found for the selected criteria.
                      </Typography>
                    </p>
                    <p>
                      <Typography>
                        Please try again or contact support for further assistance.
                      </Typography>
                    </p>
                    <img src={reportNotFoundImg} height="300px" />
                  </div>
                )}

              </div>
            )}
          </div>
        </div>
        <SlidingPane
          isOpen={ this.state.isFilterPaneOpen }
          from='right'
          width='60%'
          onRequestClose={ () => this.setState({ isFilterPaneOpen: false }) }>

          <div>
            <div style={{fontSize: "14px", textAlign: "center", fontWeight: 900, paddingBottom: "10px"}}>
               FILTERS
            </div>
            <div style={{fontSize: "14px"}}>
              Sort by
            </div>
            {searchItemLoader ? (
              <div className="offset-5 offset-lg-6" style={{
                paddingTop: '30px',
                paddingBottom: '300px',
                textAlign: "center",
                marginLeft: "auto"
              }}>
                <Loading />
              </div>
            ) : (
              <div>
                <div style={{paddingTop: "15px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        className="filter-btn"
                        size={"lg"}
                        block
                        onClick={(e) => this.handleFilterChanges(e)}>Price: Low to High
                      </Button>
                    </Col>
                  </Row>
                </div>
                <p style={{paddingTop: "5px", marginLeft: "3px", marginRight: "3px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        className="filter-btn"
                        size={"lg"}
                        block
                        onClick={(e) => this.handleFilterChanges(e)}>Price: High to Low
                      </Button>
                    </Col>
                  </Row>
                </p>
                <p style={{paddingTop: "5px", marginLeft: "3px", marginRight: "3px"}}>
                  <Row>
                    <Col sm={12} md={12} lg={12}>
                      <Button
                        className="filter-btn"
                        size={"lg"}
                        block
                        onClick={(e) => this.handleFilterChanges(e)}>Best Match
                      </Button>
                    </Col>
                  </Row>
                </p>
                <hr/>
                <div style={{fontSize: "14px"}}>
                  Price Range
                </div>
                <div style={{paddingTop: "15px", marginLeft: "3px", marginRight: "3px"}}>
                  <Form>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                          <Label for="min-price">From</Label>
                          <Input
                            className="filter_Button"
                            onChange={e => this.handlePriceRange(e)}
                            type="select"
                            value={this.state.minPrice}
                            name="min-price"
                            id="m">
                            <option disabled value={0}>
                              Min Price
                            </option>
                            {selectMinValues}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={12} lg={12}>
                        <FormGroup>
                          <Label for="max-price">To</Label>
                          <Input
                            className="filter_Button"
                            onChange={e => this.handlePriceRange(e)}
                            type="select"
                            value={this.state.maxPrice}
                            name="max-price"
                            id="m"
                            block>
                            <option disabled value={0}>
                              Max Price
                            </option>
                            {selectMaxValues}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            )}
          </div>
        </SlidingPane>
      </div>
    );
  }
}

export default SearchItem;
