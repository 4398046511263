export function formatDate(inputDate) {
  // Parse the input date string into a Date object
  const date = new Date(inputDate);

  // Extract the day, month, and year components
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();

  // Format the date components into the desired format
  return `${day}/${month < 10 ? '0' + month : month}/${year}`;
}

export function convertToHumanReadableDate(dateString) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [day, month, year] = dateString.split("/");

  // Convert month number to month name
  const monthName = monthNames[parseInt(month, 10) - 1];

  return `${day} ${monthName} ${year}`;
}
