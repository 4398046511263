import React, {Component} from 'react';
import ReferralPageBlueprint from './../Blueprints/ReferralPageBlueprint';
import CoverImage from './../../assets/single-elements/cover-image-v3.png'
import NavElements from "./NavElements";
import {Helmet} from "react-helmet";
import pdf from "../../shared/Earned-Positions-Ex-UK-Online-v2.pdf";



class CompensationPartnerProgramme extends Component {
  constructor(props) {
    super(props);
    this.supporterGuideRef = React.createRef()
    this.state = {
        isPaneOpen: false,
        isPaneOpenLeft: false,
    };

    this.onOpenSidePane = this.onOpenSidePane.bind(this)

  }

  componentDidMount() {
    if (this.supporterGuideRef && this.supporterGuideRef.current) {
      this.supporterGuideRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  onOpenSidePane = (e, ele) => {
    const { history } = this.props;
    if (ele === 'About') {
      history.push('/supporter-programme/home')
    }
    if (ele === 'How') {
      history.push('/supporter-programme/how')
    }
    if (ele === 'Compensation') {
      history.push('/supporter-programme/compensation')
    }
    if (ele === 'Tools') {
      history.push('/supporter-programme/tools')
    }
  }

  render() {
    const title = "Ex UK Online Supporters Referral Programme"
    const bImage = CoverImage
    const subHeading = "Referral Programme Rewards"
    const pubDate = "09 Jan - 3 min read"
    return (
      <div>
        <Helmet>
          <title>{ "Ex UK Online Supporter - Affiliate Programme - Earn" }</title>
          <meta name="description" content="Earn money for every sale you make, Get paid when your friends join
          Ex UK Online Supporter Programme" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <ReferralPageBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </ReferralPageBlueprint>
                  <div ref={this.supporterGuideRef} className="container">
                    <NavElements
                      currentPage={"Compensation"} onOpenSidePane={this.onOpenSidePane}
                    />
                    <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
                    </div>
                  </div>
                  <div className="supporter-program-text">
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px', paddingBottom: '10px' }}>
                      REWARDS
                    </div>
                    <div>
                      The Ex UK Online Opportunity has been designed to help you build a business that can produce
                      immediate and long-term income. <p> As a new Independent Business Owner (IBO),
                      you should set yourself a goal to learn the Compensation Plan in detail.
                      The better you understand it, the better you will make it work for you.</p>
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px', paddingBottom: '10px' }}>
                      POSITIONS & QUALIFICATIONS
                    </div>
                    <div>
                      Everyone starts the supporters programme as an Independent Business Owner.
                      <p> Your goal should be to work your way through the earned positions detailed below. The positions
                      you achieve are based on the total value of sales acquired between you and your team.
                      </p>
                      <p style={{fontSize: '18px', paddingTop: '0px'}}>
                        <a className="a-links" href={pdf}>Click here to learn more about earned positions</a>
                      </p>
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '30px', paddingBottom: '10px' }}>
                      COMMISSION FEE TABLE FOR SPECIFIC POSITIONS
                    </div>
                    <div style={{paddingBottom: '20px'}}>
                      Commission rate, based on percent of sale price, for items sold on Ex UK Online
                    </div>
                    <div className="container">
                      <table className="table table-striped">
                        <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                        <tr>
                          <th>Category</th>
                          <th>Fee</th>
                          <th>Special Condition</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td style={{fontWeight: 'bold',  borderRight: '1px solid #dee2e6'}} rowspan="2">
                            Independent Business Owner
                          </td>
                          <td>2.5%</td>
                          <td>Direct Referrals</td>
                        </tr>
                        <tr>
                          <td>2.0%</td>
                          <td>Downline Referrals</td>
                        </tr>
                        <tr>
                          <td style={{fontWeight: 'bold', backgroundColor: 'white', borderRight: '1px solid #dee2e6'}}
                              rowSpan="2">Executive Team Manager</td>
                          <td>3.0%</td>
                          <td>Direct Referrals</td>
                        </tr>
                        <tr>
                          <td>2.0%</td>
                          <td>Downline Referrals</td>
                        </tr>

                        <tr>
                          <td style={{fontWeight: 'bold',  borderRight: '1px solid #dee2e6'}} rowSpan="2">
                            Executive Team Leader
                          </td>
                          <td>4%</td>
                          <td>Direct Referrals</td>
                        </tr>
                        <tr>
                          <td>2.0%</td>
                          <td>Downline Referrals</td>
                        </tr>
                        <tr>
                          <td style={{fontWeight: 'bold', backgroundColor: 'white', borderRight: '1px solid #dee2e6'}}
                              rowSpan="2">Senior Executive Leader</td>
                          <td>4.5%</td>
                          <td>Direct Referrals</td>
                        </tr>
                        <tr style={{borderBottom: '1px solid #dee2e6'}}>
                          <td>2.0%</td>
                          <td>Downline Referrals</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }

}

export default CompensationPartnerProgramme;
