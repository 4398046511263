import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/zimbabwe-shipping-map.jpg'
import CountryImage from './../../assets/single-elements/blog-images/zimbabwe-flag-round-small.jpg'
import ImportRequestForm from './../helpers/ImportRequestForm'
import {Helmet} from "react-helmet";


class ImportCarToZimbabwe extends Component {

  render() {
    const title = "Importation of vehicles to Zimbabwe from UK Guide"
    const bImage = CoverImage
    const subHeading = "The inclusive service offered to clients in Zimbabwe"
    const pubDate = "6 May - 6 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ex UK Online - Import a car from UK to Zimbabwe</title>
          <meta name="description"
                content="Ex UK Online Blog - A Guide on the things to consider when importing a vehicle from the UK to Zimbabwe" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                  <div className="" style={{ textAlign: 'center' }}>
                    <BlogBlueprint
                      title={title}
                      bImage={CountryImage}
                      subHeading={subHeading}
                      publishingDate={pubDate}
                    >
                    </BlogBlueprint>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      We supply brand new and pre-owned vehicles from the UK to our clients based in Zimbabwe.
                      Ex UK Online is rated as the #1 vehicle exporter to Africa.
                    </p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                      To order a vehicle from our site, browse the available stock <a className="a-links" href="/">using the search feature here </a>
                      <br/>
                      OR
                      <br/>
                      Submit an import enquiry request below for a quote on any vehicle available on the UK market.

                      <br/>
                      Cars are either shipped in containers or using the ‘roll on roll off’ (RORO) option. Ex UK Online will collate and prepare
                      all documentation needed including inspection Documents, Log Books, Invoices, Bills of Lading,
                      Marine Insurance Documents, Receipts and any other documents required for shipping, and process these on your behalf
                    </p>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px' }}> Car shipping service to Harare, Zimbabwe from UK</p>

                    <div className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>

                      <p>
                        Zimbabwe is landlocked thus Ex UK Online offers a shipped and insured service to the following ports:
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Durban: </span>
                        Customer is responsible for picking up the vehicle at the port
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Walvis Bay: </span>
                        Customer is responsible for picking up the vehicle at the port
                      </p>
                      <p>
                        <span style={{fontWeight: 700}}>Dar es Salaam: </span>
                        Customer is responsible for picking up the vehicle at the port
                      </p>
                      <div style={{
                            marginTop: "25px",
                            height: '350px',
                            backgroundImage: `url(${bImage})`
                          }} className="help-department-heading"/>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>The delivery process</p>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      Importing a vehicle from the UK takes approximately 21-24 days;
                      we have one ship sailing from the UK per week. Once the vehicle is identified and payment is received,
                      Ex UK Online will ship the vehicle and send relevant documentation via DHL.
                    </p>
                    <p className="page-information" style={{paddingTop: '0px', fontSize: '15px'}}>
                      Documentation required at importation by the Zimbabwe Revenue Authority (ZIMRA) for clearance purposes:
                    </p>
                    <div className="page-information" style={{paddingTop: '0px', fontSize: '15px', textAlign: 'left'}}>
                      <ul>
                        <li>
                          V5 Logbook
                        </li>
                        <li>
                          Bill of Lading
                        </li>
                        <li>
                          Invoice or letter of sale indicating the price paid
                        </li>
                        <li>
                          Any other documents relevant to the purchase, acquisition, shipment or importation of the vehicle.
                          These may include certificate of registration and police clearance certificate.
                        </li>
                        <li>
                          Spare key (if available)
                        </li>
                      </ul>
                    </div>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px'}}>Guide on vehicle import duty in Zimbabwe </p>
                    <p className="page-information"
                       style={{fontSize: '15px', paddingTop: '0px', marginBottom: '0px'}}>
                      <span style={{fontWeight: 700}}>The following should be regarded as a guide only, </span>
                      as the final assessment is given by the Zimbabwe Revenue Authority (ZIMRA) at the time of clearance
                      at the border.
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '10px'}}>
                      The duty to be paid on importation of motor vehicles into Zimbabwe is based on the Cost, Insurance
                      and Freight (CIF) value plus other incidental charges and expenses incurred in the purchase of the
                      vehicle and its subsequent transportation up to the first point of entry into Zimbabwe
                    </p>
                    <p style={{fontSize: '15px', paddingTop: '0px'}}>
                      Before you can register your car in Zimbabwe, you have to pay Customs Duty, Surtax and VAT. Surtax
                      charge applies to motor vehicles that are more the 5 years old at the time of importation.
                    </p>

                    <div>
                      <br/>
                      <br/>
                      <h4 className="page-header-content" style={{ color: '#b60811'}}>FAQs</h4>
                      <div style={{ color: '#424242'}}>
                        <p style={{fontSize: '16px', fontWeight: 700, color: '#424242'}}>How much does it cost to ship a
                          car from UK to Zimbabwe, Harare?</p>
                        <div style={{fontSize: '15px', color: '#424242'}}>Shipping charge is calculated based on the
                          size of the vehicle. Use the table below for reference.
                        </div>
                        <div className="container" style={{fontSize: '15px'}}>
                          <table className="table table-striped">
                            <thead style={{backgroundColor: 'aliceblue', fontWeight: 'bold'}}>
                            <tr>
                              <th>Vehicle Type</th>
                              <th>Walvis Bay</th>
                              <th>Durban</th>
                              <th>Dar es Salaam</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Saloon
                              </td>
                              <td>from $800</td>
                              <td>from $900</td>
                              <td>from $900</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                SUV, 4x4 (Pick Up)
                              </td>
                              <td>from $1100.00</td>
                              <td>from $1100.00</td>
                              <td>from $1100.00</td>
                            </tr>
                            <tr>
                              <td style={{fontWeight: 'bold', borderRight: '1px solid #dee2e6'}} rowSpan="1">
                                Van
                              </td>
                              <td>from $900</td>
                              <td>from $950</td>
                              <td>from $950</td>
                            </tr>

                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <p style={{fontSize: '16px', fontWeight: 700}}>How much are vehicle import duties and taxes in Zimbabwe?</p>
                        <div style={{fontSize: '15px'}}>
                          The following should be regarded as a guide only, as the final assessment is given by the Zimbabwe Revenue Authority(ZIMRA)
                          at the time of clearance at the border.
                          <div style={{fontWeight: 700, fontSize: '15px', }}>
                            <div>
                              CUSTOMS DUTY is calculated at 40%
                            </div>
                            <div>
                              SURTAX at 35%
                            </div>
                            <div>
                              VALUE ADDED TAX (VAT) at 14.5%
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <br/>
                    <br/>
                    <p className="how-to-steps" style={{fontSize: '16px', marginBottom: '0px'}}>Quick Import enquiry request </p>
                    <div>
                      <ImportRequestForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}

export default ImportCarToZimbabwe;
