import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem, Card, CardImg, CardTitle,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText, Row, Table
} from 'reactstrap';
import moment from 'moment';
import {Loading} from "../LoadingComponent";
import routeTo from "./../helpers/RouteTo"
import {Link} from "react-router-dom";
import SupporterProgressTracker from "./SupporterProgressTracker";
import getTrainingValues from "./../helpers/GenerateTrainingProgress"



class SupporterDashboardComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

      isAgent: false,
      discountCode: "",
      discountLevel: "",
      discountCodeUsage: {},
      mobileNumber: "",
      value: "",
      error: false,
      message: "",
      multiView: 0,
      openAgentAccount: false,
      activeStep: 1,
      isChecked: false,
      code: "",
      referrerEmail: "",
      trainingProgress: {}
    }

  }
  componentDidMount() {
    const { history, location, isLoggedIn } = this.props;
    if (!isLoggedIn) {
      history.push("/login", location);
    }

    const token = localStorage.getItem('token')


    let headers = { Authorization: `token ${token}` };

    this.props.getUserProfile && this.props.getUserProfile(headers);
    this.props.getUserOrders && this.props.getUserOrders(headers);
    this.props.getAgentProfile && this.props.getAgentProfile(headers);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.getAgentProfileSuccess && prevProps.getAgentProfileSuccess !== this.props.getAgentProfileSuccess) {
      const statusCode = this.props.getAgentProfileSuccess.status
      if (statusCode === 204) {
        this.setState({isAgent: false})
      } else if (statusCode === 200) {

        let response = this.props.getAgentProfileSuccess.response
        this.setState({isAgent: true,
                              discountCode: response.code,
                              discountLevel: response.name,
                              discountCodeUsage: response.voucher_application_instances,
                              discountCodeStartTime: response.start_datetime})
      }
    }
    if (this.props.getUserProfileSuccess && prevProps.getUserProfileSuccess !== this.props.getUserProfileSuccess) {

      if (this.props.getUserProfileSuccess.agent_training == null) {
        this.setState({
          trainingProgress: {intro: false}
        })
      } else {
        let sample = JSON.parse(this.props.getUserProfileSuccess.agent_training)
        let completeObj = {intro: getTrainingValues(sample, "intro"),
          chapter_1: getTrainingValues(sample, "chapter_1"),
          chapter_2: getTrainingValues(sample, "chapter_2"),
          chapter_3: getTrainingValues(sample, "chapter_3")}
        this.setState({
          trainingProgress: completeObj
        })
      }
    }
  }

  renderAgentInfo(isAgent, discountCode, discountLevel, discountCodeUsage) {
    if ((isAgent) && (discountCodeUsage.usage_details.length > 0))  {
      return discountCodeUsage.usage_details.map(
        ({ order_number, usage_date, status, is_agent_paid }, i) => {
          return (
            <div className="col-12 col-md-12 col-lg-12 mt-12 search-card" key={i}>
              <div style={{
                fontSize: "20px",
                fontWeight: 600,
                paddingTop: '30px',
                paddingBottom: '30px',
                textAlign: 'center' }}>
                Promo code uses
              </div>
              <hr/>
              <Card className="cardSearch">
                <Link to="#">
                  <div className="row">
                    <div className="col col-sm-4 col-md-2 col-lg-3 search-card-img-div">
                      <CardImg
                        src={require("../../assets/single-elements/"+ discountLevel+ ".png")}
                        className="mx-auto car-img img-responsive card-img-top"
                        id="search-card-imgsdf"
                      />
                    </div>
                    <div className="col col-sm-8 col-md-6 col-lg-6 contentTitles">
                      <div className="topPriceContainer">
                        <span className="price">
                          # {order_number}
                        </span>
                      </div>
                      <div className="container ordersCardProperties" style={{letterSpacing: '0px', color: '#424242'}}>
                        Order Status: {status}
                      </div>
                      <CardTitle className="container ordersCardProperties" style={{letterSpacing: '0px', color: '#424242'}}> Commission payment: {is_agent_paid ? 'PAID': 'PENDING'}</CardTitle>
                      <div className="container ordersCardProperties" style={{letterSpacing: '0px', color: '#424242'}}>
                        Order date: {usage_date && moment(usage_date).format('MMM. D, YYYY')}
                      </div>
                    </div>
                    <div className="col col-sm-3 col-md-3 col-lg-3 d-flex flex-column justify-content-between align-items-center price-container">
                      <span className="price">
                        # {order_number}
                      </span>

                    </div>
                  </div>
                </Link>
              </Card>
          </div>

          )
        }
      );
    } else {
      return (
        <div className="col-12 col-md-12 col-lg-12 mt-12" style={{textAlign: 'center'}}>
          No commission earned yet
        </div>
      );
    }
  }


  render() {
  const {history} = this.props
  const {isAgent, discountCode, discountLevel, discountCodeUsage, discountCodeStartTime, trainingProgress } = this.state;
  let points = 0;
  let now = 50;
  let completeProfile = false

  if (Object.values(trainingProgress).includes(false)) {
    points = 0
    now = 50
  } else {
    points = 100
    now = 100
    completeProfile = true
  }

  return <div>
    <div className="container cpanel">
        <Breadcrumb>
          <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
          <BreadcrumbItem><Link to="/profile" >Account</Link></BreadcrumbItem>
          <BreadcrumbItem active>Supporter dashboard</BreadcrumbItem>
        </Breadcrumb>
      <div className="row" style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <div className="page-header col-12">
          <h4 className="page-header-content">Supporter Dashboard</h4>
          <hr className="fancy-line"/>
          <SupporterProgressTracker now={now} completeProfile={completeProfile}/>
        </div>
      </div>
      <div style={{paddingRight: '5px', paddingLeft: '5px'}} className="supporter-program-text">
        <div className="container">
          <div className="row">
            <Col sm={6} className="">
              <ListGroup style={{margin: "15px", cursor:'pointer'}}>
                <ListGroupItem style={{backgroundColor: '#009150', textAlign: 'center'}}
                               onClick={() => routeTo(history, "Downline")}
                >
                  <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>Points</ListGroupItemHeading>
                  <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
                    {points}
                  </ListGroupItemText>
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col sm={6} className="">
              <ListGroup style={{margin: "15px", cursor:'pointer'}}>
                <ListGroupItem style={{backgroundColor: '#3f51b5', textAlign: 'center'}}
                               onClick={() => routeTo(history, "Downline")}
                >
                  <ListGroupItemHeading style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>My Downline</ListGroupItemHeading>
                  <ListGroupItemText style={{fontSize: "18px", fontWeight: 900, color: 'white'}}>
                    <img
                      className="link-icons"
                      src={require("../../assets/single-elements/icons8-multicultural-people-52.png")}
                      alt="icons"
                    />
                  </ListGroupItemText>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </div>
          <br/>
        </div>
          <div style={{
              backgroundColor: 'whitesmoke',
              marginTop: '0x',
              paddingTop: '20px',
              marginBottom: '40px',
              borderTop: 'solid 1px #e8e8e8'}}>
            <Row style={{paddingBottom: '25px'}}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div style={{fontWeight: 600}}>
                  Training
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div>
                  <a href="/supporter-training" className="link-text">View Training Resources</a>
                </div>
              </Col>
            </Row>
            <Row style={{paddingBottom: '25px'}}>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div style={{fontWeight: 600}}>
                  Promoting
                </div>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <div>
                  <a href="/advertising-tools" className="link-text">Download Promotion Media</a>
                </div>
              </Col>
            </Row>
          </div>
        <div>
          <div style={{ fontSize: "20px", fontWeight: 600, paddingBottom: '40px', textAlign: 'center' }}>
            Promo code details
          </div>
        </div>
        <Row style={{ paddingBottom: '40px'}}>
          <Col sm="12">
          <Table bordered >
              <tbody>
                <tr style={{backgroundColor: '#efefef'}}>
                  <td className="tbl-header">PROMO CODE</td>
                  <td className="tbl-data">{discountCode}</td>
                </tr>
                <tr>
                  <td className="tbl-header">LEVEL</td>
                  <td className="tbl-data">{discountLevel}</td>
                </tr>
                <tr  style={{backgroundColor: '#efefef'}}>
                  <td className="tbl-header">VALID FROM</td>
                  <td className="tbl-data">
                    {discountCodeStartTime && moment(discountCodeStartTime).format('MMM. D, YYYY, h:mm A')}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
            {this.renderAgentInfo(isAgent, discountCode, discountLevel, discountCodeUsage)}
        </Row>
      </div>
    </div>
  </div>;
  }
}

export default SupporterDashboardComponent;