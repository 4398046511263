import React from 'react';
import {Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import {Loading} from "../LoadingComponent";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';
import safeCheckout from '../../assets/images/safe-checkout.jpg';

class SomeComponent extends React.Component {

  renderBasketData(banana){
    return banana.map(
      ({ title, quantity, price_incl_tax }, i) => {
        return (
          <div style={{paddingBottom: '12px'}}>
            - {title} <span style={{color: "gray"}}>x {quantity} - {" "}
            <span className="sale-price" style={{fontWeight: "bold"}}>£{price_incl_tax}</span></span>
          </div>
        );
      }
    )
  }


  render() {

  const {someProp, toggle, onLogin, onLoading, onChange, onPayment} = this.props

  let message = ""

  if (someProp.modalMessage === "401") {
    message = "You're currently not logged in"
  } else {
    message = "Unable to proceed - Contact customer service, quoting the above error message"
  }

  let arr = Object.values(someProp.basket)
  return <div>

    <div>{this.props.name}</div>
      <div>
         <div>

        <Modal isOpen={someProp.showModal} toggle={toggle.bind(this,false)}>
          <ModalHeader toggle={toggle.bind(this,false)}>{someProp.modalMessage + " Error"}</ModalHeader>
          <ModalBody style={{ textAlign:'center'}}>
            {message}<p></p>
          </ModalBody>
          <ModalFooter>
            {message && message.includes("401") ? <Button color="primary"  onClick={onLogin.bind(this,false)}>Login</Button>
              : <Button color="primary"  onClick={toggle.bind(this,false)}>Okay</Button>
            }

            <Button color="secondary"  onClick={toggle.bind(this,false)}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
        <div style={{paddingBottom: "64px"}}>
          <Card className="bg-light">
            <CardBody className="text-center">
              <div className="basket-page-information checkout-item-title">Delivery: </div>
              <div className="basket-page-information basket-page-information-bottom">
                {someProp.userTitle} { someProp.firstName } { someProp.lastName }
                <br />
                { someProp.userEmail }
                <br />
                { someProp.contactNumber }
                <br />
              </div>
            </CardBody>
          </Card>
          <hr className="checkout-info-block" />
          <Card className="bg-light">
            <CardBody style={{color:"#6fa8dd"}} className="text-center">
              <div className="basket-page-information checkout-item-title"> Your Order: </div>
              <div className="basket-page-information" style={{color: '#000000', fontWeight: 500}}>
                {this.renderBasketData(arr)}
              </div>
            </CardBody>
          </Card>

          <Card className="bg-light">
            <CardBody style={{color:"#6fa8dd"}} className="text-center">
              <div className="basket-page-information checkout-item-title"> Total : </div>
              <div className="basket-page-information basket-page-information-bottom"
                   style={{color: '#000000'}}>
                <span className="sale-price" > £{ someProp.totalCost }</span>
                <br />
              </div>
            </CardBody>
          </Card>
          <hr className="checkout-info-block" />
          <div className="text-center">
            <br />
            <div className="basket-page-information checkout-item-title"> Payment Method: </div>
            <Row className="payment-methods">
              <Col>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="payment"
                    // value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="visa" control={<Radio />} label="Visa" />
                    {/*<FormControlLabel*/}
                    {/*  value="cash"*/}
                    {/*  control={<Radio />}*/}
                    {/*  label="*Cash or Card on delivery"*/}
                    {/*/>*/}
                  </RadioGroup>
                </FormControl>
              </Col>
              <Col>
                <img src={safeCheckout} alt="Safe checkout" height="75"/>
              </Col>
            </Row>
          </div>
          { onLoading ? (
            <div className="offset-1 offset-lg-1" style={{backgroundColor: '#f8f9fa'}}>
              <Loading />
            </div>
          )
          :
          (
            <div className="col" style={{textAlign: 'center'}}>
              <Button
                className="delete-basket-button"
                size="sm"
                onClick={onPayment.bind(this)}>
                Next
              </Button>
            </div>
          )}
        </div>
        <br/>
      </div>
  </div>;
  }
}

export default SomeComponent;