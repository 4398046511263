import React, { Component } from "react";
import ImgAndForm from "./ImgAndForm";
import ShopByMake from "./../containers/ShopByMakeContainer";
import BestDeals from "./../containers/BestDealsContainer";


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShopByMake,
      search: {},
      ageVerificationModal: false
    };

    this.onSearch = this.onSearch.bind(this);
    this.toggleAgeModal = this.toggleAgeModal.bind(this);
  }

  componentDidMount() {
    document.body.scrollTop = 0
    window.scrollTo(0,0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    document.body.scrollTop = 0
    window.scrollTo(0,0);
  }

  onSearch(search) {
    this.props.history.push(`${search}`);
  }

  toggleAgeModal = () => {
    localStorage.setItem("ageVerified", "true")
    this.setState({
      ageVerificationModal: false
    });
  }


  render() {
    return (
      <div style={{ backgroundColor: "grey" }}>
        {/* <ImgAndForm onSearch={this.onSearch} /> */}
        <section className="gen-section">
          <div className="container-fluid custom-container-fluid">
            <div className="row">
              <div className="col-lg-12 col-sm-12">

                <ImgAndForm onSearch={this.onSearch} />

                <BestDeals />

              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
