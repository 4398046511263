import React from "react";
import {Col, Card, CardBody, Row, CardSubtitle, CardText, CardHeader, CardFooter, Button} from "reactstrap";
import {withRouter} from "react-router-dom";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

const stringSorten = str => {
  const arrayOfString = str ? str.trim().split(" ") : null;
  if (!arrayOfString) return
  if ((arrayOfString && arrayOfString[0].length > 8) || arrayOfString[0].length > 8) {
    return arrayOfString[0];
  }
  return arrayOfString[0] + " " + arrayOfString[1] + " " + arrayOfString[2];
};

const routeToDetails = (props) => {
  const targetSection = document.getElementById("enter-reg");
  if (targetSection) {
    targetSection.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

const PromoElements = (props) => {
   return(
     <div onClick={() => routeToDetails(props)} className="col-lg-6 col-md-6 col-12 mt-4"
          style={{marginBottom: '1.5rem', cursor: 'pointer'}} key={props.i}>
       <Card>
         <CardHeader>
           {props.d.prod_type}
         </CardHeader>
         <CardBody style={{ textAlign: 'center', padding: '2rem 0.5rem'}}>
           <div className="car-body-wrapper cards-body">
             <CardSubtitle style={{ paddingBottom: "10px", color: "black", fontWeight: 800, fontSize: "1rem"}}>
               {/*eslint-disable-next-line*/}
               <div>
                 This includes:
               </div>{' '}
             </CardSubtitle>
             <CardText>
               {props.d.options && props.d.options.map((d, i) => {
                 return (
                   <div>
                     <Row>
                       <Col xs={5} style={{textAlign: 'right'}}>
                         {d.available
                           ? <CheckCircleOutlineRoundedIcon style={{fill: "green"}}/>
                           : <CancelOutlinedIcon style={{fill: "#c60000"}}/>
                         }

                       </Col>
                       <Col xs={7} style={{textAlign: 'left'}}>
                         {d.item}
                       </Col>
                     </Row>
                   </div>
                 )
               })}
             </CardText>
           </div>

         </CardBody>
         <div style={{
           padding: '4px 6px',
         }}>
         </div>
         <CardFooter style={{textAlign: 'center'}}>
           <Button className="shop-now" id="shop-now">
             Check
           </Button>
         </CardFooter>
       </Card>
      </div>
    );

}

export default withRouter(PromoElements);