import {
  ADD_TO_CART,
  CLEAR_CART,
  DELETE_CART_ITEM,
  MERGE_CART,
  MERGE_CART_FAILURE,
  MERGE_CART_SUCCESS
} from "../constants";

export class cartAction {
  // Add item to cart
  static addCartProduct(payload) {
    return {
      type: ADD_TO_CART,
      payload
    };
  }

  // Delete Item from cart
  static deleteCartProduct(payload) {
    return {
      type: DELETE_CART_ITEM,
      payload
    };
  }

  // Clear Cart
  static clearCart(payload) {
    return {
      type: CLEAR_CART,
      payload
    }
  }

  // Api Merge Cart
  static mergeCart(payload) {
    return {
      type: MERGE_CART,
      payload
    };
  }

  static mergeCartSuccess(payload) {
    return {
      type: MERGE_CART_SUCCESS,
      payload
    }
  }

  static mergeCartFailure(error) {
    return {
      type: MERGE_CART_FAILURE,
      error
    }
  }

}