import React, {useRef, useEffect, useState} from "react";
import { convertToHumanReadableDate } from "../helpers/logic";
import {
  Row, Col
} from "reactstrap";


export default function MotComponent(props) {
  const [notification, setNotification] = useState("")
  // const motOverview = {'TestDate': 'TEST DATE', 'TestResult': 'TEST RESULT', 'TestNumber': 'TEST NUMBER', 'Mileage': 'Mileage'}
  return (
    <div>
      {/*MOT Test*/}
      <div className="mot-date">
        <h7> { convertToHumanReadableDate(props.d.TestDate) } </h7>
      </div>
      <Row className="mot-meta">
        <Col>
          <Row className="mot-meta-header">
            RESULT:
          </Row>
          <Row>
           {props.d.TestResult}
          </Row>
        </Col>
        <Col>
          <Row className="mot-meta-header">
            TEST DATE:
          </Row>
          <Row>
           { props.d.TestDate}
          </Row>
        </Col>
        <Col>
          <Row className="mot-meta-header">
            TEST NUMBER:
          </Row>
          <Row>
           {props.d.TestNumber}
          </Row>
        </Col>
        <Col>
          <Row className="mot-meta-header">
            MILEAGE:
          </Row>
          <Row>
            {props.d.OdometerReading}
          </Row>
        </Col>
        <Col>
          <Row className="mot-meta-header">
            ADVISORIES:
          </Row>
          <Row>
            {props.d.AdvisoryNoticeCount}
          </Row>
        </Col>
      </Row>
      { props.d.FailureReasonList.length === 0 && props.d.AdvisoryNoticeList.length === 0 &&
        (<div className="mot-pass">
          MOT Pass with no advisory
        </div>)
      }
      <Row>
        {props.d.FailureReasonList && props.d.FailureReasonList.length > 0 && (
            <div className="mot-defects-header">
             Major Defects
            </div>
        )}
        {props.d.FailureReasonList && props.d.FailureReasonList.map((failure, idx) => {
          return (
            <div className="mot-failure">
              {failure}
            </div>
          )
        })}
      </Row>
      <Row>
        {props.d.AdvisoryNoticeList && props.d.AdvisoryNoticeList.length > 0 && (
            <div className="mot-advisory-header">
             Advisory Notices
            </div>
        )}
        {props.d.AdvisoryNoticeList && props.d.AdvisoryNoticeList.map((advisory, idx) => {
          return (
            <div className="mot-advisory">
              {advisory}
            </div>
          )
        })}
      </Row>
    </div>
  )
}