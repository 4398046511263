import Login from './../components/LoginComponent';
import { connect } from "react-redux";
import {authAction, cartAction, vehicleAction} from "./../store/actions";

const mapStateToProps = state => {
    const {
        authReducer: {
            registerUserSuccess, 
            registerUserError, 
            registerUserLoader,
            apiTokenAuthSuccess, 
            apiTokenAuthError, 
            apiTokenAuthLoader,
            passwordResetSuccess,
            passwordResetError,
            passwordResetLoader,
            isLoggedIn
        },
        cartReducer: {
            cartProducts,
            mergeCartSuccess
        },
        vehicleReducer: {
            getCountriesSuccess
        }
    } = state;
    return {
        getCountriesSuccess,
        registerUserSuccess,
        registerUserError,
        registerUserLoader,
        apiTokenAuthSuccess,
        apiTokenAuthError,
        apiTokenAuthLoader,
        passwordResetSuccess,
        passwordResetError,
        passwordResetLoader,
        isLoggedIn,
        cartProducts,
        mergeCartSuccess
    };
};


const mapDispatchToProps = dispatch => {
    return {
        registerUser: payload =>
            dispatch(authAction.registerUser(payload)),
        apiTokenAuth: payload =>
            dispatch(authAction.apiTokenAuth(payload)),
        getCountries: payload =>
            dispatch(vehicleAction.getCountries(payload)),
        passwordReset: payload =>
          dispatch(authAction.passwordReset(payload)),
        socialApiAuth: payload =>
            dispatch(authAction.socialApiAuth(payload)),
        mergeCart: payload =>
          dispatch(cartAction.mergeCart(payload)),
        clearCart: payload =>
          dispatch(cartAction.clearCart(payload))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);