import React from 'react';
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types';

export const Loading = ({ message = 'Loading...', height = '50vh' }) => {
    return (
        <section className = "gen-section2" style={{ height: height }}>
        <div className="container text-center">
            <div className="row">
                <div className="col-12">
                    <span className="fa fa-spinner fa-pulse fa-3x fa-fw blue-loader"></span>
                    <p  className="mt-2">
                        <Typography variant="body1">
                            { message }
                        </Typography>
                    </p>
                </div>
            </div>
        </div>
        </section>
    );
};

Loading.propTypes = {
    message: PropTypes.string,
    height: PropTypes.string,
};