import React, {Component} from 'react';
import ReferralPageBlueprint from './../Blueprints/ReferralPageBlueprint';
import CoverImage from './../../assets/single-elements/cover-image-v3.png'
import {Helmet} from "react-helmet";
import NavElements from "./NavElements";


class PartnerProgrammeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isPaneOpen: false,
        isPaneOpenLeft: false,
    };

    this.onOpenSidePane = this.onOpenSidePane.bind(this)

  }

  onOpenSidePane = (e, ele) => {
    const { history } = this.props;

    if (ele === 'About') {
      history.push('/supporter-programme/home')
    }
    if (ele === 'How') {
      history.push('/supporter-programme/how')
    }
    if (ele === 'Compensation') {
      history.push('/supporter-programme/compensation')
    }
    if (ele === 'Tools') {
      history.push('/supporter-programme/tools')
    }
  }

  render() {
    const title = "Ex UK Online Supporters Referral Programme"
    const bImage = CoverImage
    const subHeading = "Welcome to the Ex UK Online Supporter Programme"
    const pubDate = "09 Jan - 3 min read"
    const formatArea = function(val){
      return <>{val + ' '}&#187;</>
    }
    return (
      <div>
        <Helmet>
          <title>{ "Ex UK Online Supporter Programme - Central" }</title>
          <meta name="description" content="Join Today and Make money with Ex UK Online Supporter Programe" />
        </Helmet>
        <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                <div className="" style={{ textAlign: 'center' }}>
                  <ReferralPageBlueprint
                    title={title}
                    bImage={bImage}
                    subHeading={subHeading}
                    publishingDate={pubDate}
                  >
                  </ReferralPageBlueprint>
                  <div className="container">
                    <NavElements
                        currentPage={"About"} onOpenSidePane={this.onOpenSidePane}
                    />
                    <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
                    </div>
                  </div>
                  <div className="supporter-program-text">
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px' }}>
                      How The Ex UK Supporter Programme Works?
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                      The Supporter Programme is a referral program where Ex UK Online pays commissions to supporters who send
                      traffic to the site, that results in a sale.
                      <p>
                        The programme serves as the intermediary between traffic partners (supporters)
                        and the site. In order to make sure our supporters thrive, we provide you with
                        the tools, reports, and resources you need to succeed.
                      </p>
                    </div>
                    <div style={{ fontSize: "20px", fontWeight: 600, paddingTop: '15px' }}>
                      Join for Free and Start Earning Money Today!
                    </div>
                    <div style={{ paddingTop: '10px' }}>
                      Be one of our Ex UK Online Supporters and build your own successful business.
                    </div>
                    <div>
                      <section className="gen-section  filter_space">
                        <div className="container mobile_padding">
                          <div className="row">
                          <div className="col-lg-12 col-sm-12">
                            <div className="static-info-page">
                              <div className="" style={{ textAlign: 'center' }}>
                                <div className="col-lg-12">
                                  <div className="row mt-4 bottom-out-space">
                                    <div className="col-lg-12 help-cards">
                                      <div className="inner-how-to-cards"
                                           // style={{boxShadow: '0 2px 4px 0 rgba(0,0,0,.5)'}}
                                      >
                                      <div className="a-column" style={{width: '100%'}}>
                                        <h6 className="info-graphics-heading"
                                        style={{ fontSize: "18px"}}>
                                          GET STARTED IN THREE SIMPLE STEPS
                                        </h6>
                                        <div className="row">
                                          <div className="col-lg-4 col-md-4 col-sm-12" style={{paddingBottom: '30px', paddingLeft: '5px', paddingRight: '5px'}} >
                                            <div className="mx-auto car-img img-responsive card-img-top how-to-steps"
                                                 style={{fontSize: '16px', cursor: 'pointer'}} onClick={(e) => this.onOpenSidePane(e, "How")}>
                                              1. REGISTER
                                              <br/>
                                              <div style={{paddingTop: '20px'}}>
                                                <img
                                                  className="link-icons"
                                                  style={{width: '100px'}}
                                                  src={require("../../assets/single-elements/icons8-form-96.png")}
                                                  alt="icons"
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              Its easy and free to join.
                                              <p>
                                                Get up and running today. Just one approval to join.
                                              </p>
                                              <a href='/supporter-programme/how' className="link-text">
                                                 {formatArea('Join Now')}
                                              </a>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-sm-12" style={{paddingBottom: '30px', paddingLeft: '5px', paddingRight: '5px'}} >
                                            <div className="mx-auto car-img img-responsive card-img-top how-to-steps"
                                                 style={{fontSize: '16px', cursor: 'pointer'}} onClick={(e) => this.onOpenSidePane(e, "Tools")}>
                                              2. ADVERTISE
                                            <br/>
                                              <div style={{paddingTop: '20px'}}>
                                                <img
                                                  className="link-icons"
                                                  style={{width: '100px'}}
                                                  src={require("../../assets/single-elements/icons8-user-group-2-96.png")}
                                                  alt="icons"
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              Choose from over a million products
                                              <p>
                                                 to advertise to your customers.
                                              </p>
                                              <a href='/supporter-programme/tools' className="link-text">
                                                {formatArea('Learn More')}
                                              </a>
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-4 col-sm-12" style={{paddingBottom: '30px', paddingLeft: '5px', paddingRight: '5px'}} >
                                            <div className="mx-auto car-img img-responsive card-img-top how-to-steps"
                                                 style={{fontSize: '16px', cursor: 'pointer'}} onClick={(e) => this.onOpenSidePane(e, "Compensation")}>
                                              3. GET PAID
                                            <br/>
                                              <div style={{paddingTop: '20px'}}>
                                                <img
                                                  className="link-icons"
                                                  style={{width: '100px'}}
                                                  src={require("../../assets/single-elements/icons8-money-80.png")}
                                                  alt="icons"
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              Earn up to 10% commission
                                              <p>
                                                 for each sale you make
                                              </p>
                                              <a href='/supporter-programme/compensation' className="link-text">
                                                {formatArea('Learn More')}
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 help-cards" style={{paddingTop: '25px'}}>
                                      <div className="inner-how-to-cards" style={{border: '0px'}}>
                                      <div className="a-column" style={{width: '100%'}}>
                                        <div
                                        style={{ fontSize: "20px", fontWeight: 600}}>
                                          Benefits of Ex UK Online Supporters Programme
                                        </div>
                                        <div style={{ margin: "auto", width: '90%', paddingTop: '10px'}}>
                                          Ex UK Supporter Programme, enables you to earn money for each referral
                                          you make and your friend can get a discount. In addition you can earn money from the
                                          sales of friends you refer to the programme (downline). The reward is limitless.
                                          The more friends you refer, the more reward you will earn.
                                          So join today and start making money easily!
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-6 col-md-6 col-sm-12" style={{paddingBottom: '20px', paddingLeft: '5px', paddingRight: '5px'}} >
                                            <div className="mx-auto car-img img-responsive card-img-top how-to-steps"
                                                 style={{fontSize: '16px',  height: '255px'}} >
                                              <div style={{paddingTop: '30px'}}>
                                                <p>
                                                  1. DIRECT REFERRAL INCOME
                                                </p>
                                                <img
                                                  className="link-icons"
                                                  style={{width: '250px'}}
                                                  src={require("../../assets/single-elements/make-money-v3.png")}
                                                  alt="icons"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-12" style={{paddingBottom: '20px', paddingLeft: '5px', paddingRight: '5px'}} >
                                            <div className="mx-auto car-img img-responsive card-img-top how-to-steps"
                                                 style={{fontSize: '16px', height: '255px'}}>
                                              <div style={{paddingTop: '30px'}}>
                                                <p>
                                                  2. CUSTOMER DISCOUNT
                                                </p>
                                                <img
                                                  className="link-icons"
                                                  style={{width: '235px'}}
                                                  src={require("../../assets/single-elements/discount-image-promo.png")}
                                                  alt="icons"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12" style={{paddingBottom: '20px', paddingLeft: '5px', paddingRight: '5px'}} >
                                            <div className="mx-auto car-img img-responsive card-img-top how-to-steps"
                                                 style={{fontSize: '16px',  height: '355px'}} >
                                              <div style={{paddingTop: '30px'}}>
                                                <p>
                                                  3. RESIDUAL INCOME FROM RECRUITS
                                                </p>
                                                <div
                                                  className="supporter-programme-recruits-pane"
                                                  style={{height: '350px', borderRadius: '4px'}}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                 </div>
                                </div>
                                <hr />
                              </div>
                            </div>
                         </div>
                         </div>
                        </div>
                      </section>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }

}

export default PartnerProgrammeComponent;
