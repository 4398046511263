import Home from "./../components/SearchPartnerEB/SearchEBComponent";
import { connect } from "react-redux";
import {cartAction, sideMenuAction, vehicleAction} from "./../store/actions";

const mapStateToProps = state => {
  const {
    authReducer: { isLoggedIn },
    vehicleReducer: {
      searchItemSuccess,
      searchItemError,
      searchItemLoader,
      addProductToBasketSuccess,
      addProductToBasketError,
      addProductToBasketLoader,
      getAllProductsSuccess
    },
    cartReducer: {
      cartProducts,
      mergeCartSuccess,
      mergeCartLoader,
      cartProductsReduced
    },
  } = state;
  return {
    searchItemSuccess,
    searchItemError,
    searchItemLoader,
    addProductToBasketSuccess,
    addProductToBasketError,
    addProductToBasketLoader,
    getAllProductsSuccess,
    cartProducts,
    mergeCartSuccess,
    mergeCartLoader,
    cartProductsReduced,
    filterReducer: state.filterReducer,
    sideMenuReducer: state.sideMenuReducer,
    isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchItem: payload => dispatch(vehicleAction.searchItem(payload)),
    sideMenuAction: payload => dispatch(sideMenuAction.setSideMenu(payload)),
    addProductToBasket: payload => dispatch(vehicleAction.addProductToBasket(payload)),
    getAllProducts: payload => dispatch(vehicleAction.getAllProducts(payload)),
    addCartProduct: payload => dispatch(cartAction.addCartProduct(payload)),
    mergeCart: payload => dispatch(cartAction.mergeCart(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
