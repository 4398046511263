import React from 'react';
import {Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback, AvField } from 'availity-reactstrap-validation';
import Typography from "@material-ui/core/Typography";


const ReportStepOneComponent = ({
                                  reg,
                                  onChange,
                                  onBuy,
                                  error,
                                  notification
}) => {
  return (
    <div>
      <div>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page">
                  <div className="" style={{textAlign: 'center'}}>
                    {notification &&
                      <div style={{padding: "16px 0px 8px 0px"}}>
                        <Alert color={error ? "danger" : "success"}>
                          {notification}
                        </Alert>
                      </div>
                    }
                    <div className="col-lg-6 col-sm-12 offset-lg-3">
                      <div>
                        <h4 className="page-header"> Premium Report </h4>
                        <br/>
                        <Typography variant="h5" className="typo-text">
                          <span className="report-plate"> {reg} </span>
                        </Typography>
                        <br/>
                      </div>
                      <AvForm onValidSubmit={onBuy.bind(this, 3, false)} style={{padding: '20px'}}>
                        <div>
                          {/*<h4 className="page-header"> Your Details </h4>*/}
                          <Typography style={{textAlign: 'left'}}
                                      variant="body1">
                            Download the report instantly after the payment.
                            If you like, we can send the report to your e-mail.
                          </Typography>
                        </div>
                        <br/>
                        <AvGroup>
                          <Label for="userEmail" className="form-input">Email</Label>
                          <AvInput
                            name="userEmail"
                            type="email"
                            onChange={onChange}
                            required
                            placeholder="Your email address"
                            maxLength={255}
                          />
                          <AvFeedback>A valid email is required to place order.</AvFeedback>
                        </AvGroup>
                        <br/>
                        <div className="col">
                          <Button size={"sm"} className="delete-basket-button">Next</Button>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr/>
      </div>
    </div>
  )
}

export default ReportStepOneComponent;