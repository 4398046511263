
import {withRouter} from "react-router-dom";
import React from "react";


const AgentSuccess = (props) => {
   return(
     <div style={{ maxWidth: '500px', margin: 'auto', padding: '10px',
       textAlign: 'center', fontSize: '13px' }}>
       <i className="fa fa-check-circle" style={{fontSize:"40px", color:"#009800"}}></i>
            <p></p>
       <p style={{fontWeight: 600, fontSize: '14px'}}>Congratulations!</p>
       <p style={{fontSize: '14px'}}>Welcome to the ExUKonline Team</p>
       Find your discount code below. Use this code to earn commission.
       <p> For more information on how to use your code click here</p>
      </div>
    );

}

export default withRouter(AgentSuccess);
