import { connect } from "react-redux";
import { authAction } from "./../store/actions";
import {UPDATE_TRAINING_PROGRESS_SUCCESS} from "../store/constants";

let Components = {
  'ProfileComponent': require('./../components/Profile/ProfileComponent').default,
  'DownlineComponent': require('../components/Profile/DownlineComponent').default,
  'AdvertisingComponent': require('../components/Profile/PromoToolsComponent').default,
  'TrainingComponent': require('../components/Profile/TrainingComponent').default,
  'SupporterDashboardComponent': require('../components/Profile/SupporterDashboardComponent').default
};

const mapStateToProps = state => {
    const {
        authReducer: { getUserProfileSuccess, getUserProfileError, getUserProfileLoader,
                        getUserOrdersSuccess, getUserOrdersError, getUserOrdersLoader, isLoggedIn,
                        getAgentProfileSuccess, getAgentProfileError, getAgentProfileLoader,
                        upgradeToAgentAccountLoader, upgradeToAgentAccountSuccess, upgradeToAgentAccountError,
                        getDownloadFileSuccess, getDownloadFileError, getDownloadFileLoader,
                        updateTrainingProgressSuccess, updateAgentTrainingError, updateAgentTrainingLoader


        }
    } = state;
    return {
        getUserProfileSuccess,
        getUserProfileError,
        getUserProfileLoader,
        getUserOrdersSuccess,
        getUserOrdersError,
        getUserOrdersLoader,
        isLoggedIn,
        getAgentProfileSuccess,
        getAgentProfileError,
        getAgentProfileLoader,
        upgradeToAgentAccountError,
        upgradeToAgentAccountSuccess,
        upgradeToAgentAccountLoader,
        getDownloadFileSuccess,
        getDownloadFileError,
        getDownloadFileLoader,
        updateTrainingProgressSuccess,
        updateAgentTrainingError,
        updateAgentTrainingLoader
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserProfile: payload =>
            dispatch(authAction.getUserProfile(payload)),
        apiTokenLogout: payload =>
            dispatch(authAction.apiTokenLogout(payload)),
        getUserOrders: payload =>
          dispatch(authAction.getUserOrders(payload)),
        getAgentProfile: payload =>
          dispatch(authAction.getAgentProfile(payload)),
        upgradeToAgentAccount: payload =>
          dispatch(authAction.upgradeToAgentAccount(payload)),
        getDownloadFile: payload =>
          dispatch(authAction.getDownloadFile(payload)),
        updateTrainingProgress: payload =>
          dispatch(authAction.updateTrainingProgress(payload))
    };
};


export default (componentName) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Components[componentName]);
}